import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1024.000000 1024.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)">
       <path d="M0 9340 c0 -593 3 -900 10 -900 6 0 11 9 11 20 1 11 2 26 3 33 0 6
13 13 28 15 16 2 28 7 28 11 0 4 8 7 18 7 9 -1 18 4 19 9 3 10 -6 12 -66 14
-32 1 -33 2 -18 19 15 16 15 19 -4 30 -26 16 -15 32 22 32 26 0 29 3 29 33 0
17 -4 39 -10 47 -12 19 6 40 35 40 34 0 55 -10 55 -26 0 -7 6 -11 13 -9 7 3
12 23 12 47 0 35 4 43 20 46 16 2 17 1 6 -7 -12 -7 -12 -13 -3 -24 13 -15 65
-18 70 -4 6 20 0 29 -18 24 -30 -8 -24 12 8 24 l27 11 -27 10 c-16 5 -28 17
-28 25 0 12 7 14 38 8 20 -4 38 -8 38 -8 1 -1 9 -19 17 -39 13 -31 13 -37 1
-42 -30 -11 -6 -65 40 -91 22 -12 41 -65 61 -164 8 -41 21 -83 28 -95 8 -12
18 -37 22 -56 4 -19 14 -55 22 -80 8 -25 19 -67 23 -95 5 -27 16 -81 25 -120
29 -122 55 -286 71 -435 16 -155 17 -492 2 -569 -5 -25 -10 -75 -12 -111 -2
-36 -11 -119 -20 -185 -9 -66 -23 -178 -31 -250 -17 -147 -25 -211 -70 -540
-10 -76 -13 -92 -23 -146 -6 -30 -16 -110 -23 -179 -6 -69 -22 -206 -35 -305
-13 -99 -26 -211 -29 -249 -3 -38 -9 -74 -13 -80 -8 -14 -21 -110 -26 -206 -6
-90 -14 -141 -28 -157 -5 -7 -7 -17 -3 -23 3 -5 2 -10 -4 -10 -5 0 -11 -12
-13 -27 -2 -22 -9 -29 -31 -31 -32 -4 -57 12 -57 37 0 9 -17 23 -40 33 -36 15
-40 20 -40 52 0 19 -7 41 -15 50 -15 15 -21 46 -9 46 4 0 12 -8 19 -17 11 -15
13 -15 24 6 9 17 10 26 1 36 -14 17 -5 36 14 29 19 -7 28 22 10 36 -10 9 -14
9 -14 1 0 -24 -29 -10 -32 16 -4 36 -35 43 -48 11 -8 -20 -9 -17 -7 17 2 34
-1 40 -19 43 -25 4 -41 62 -17 62 7 0 13 -9 13 -20 0 -13 7 -20 18 -20 15 0
17 6 14 36 -4 30 0 40 19 55 l24 18 -22 22 c-20 20 -23 21 -29 6 -3 -9 -12
-17 -20 -17 -8 0 -14 -8 -14 -17 0 -13 -3 -14 -12 -5 -9 9 -8 16 5 30 9 10 17
26 17 35 0 9 8 22 19 29 26 18 36 55 19 75 -11 15 -10 16 10 10 23 -5 23 -5 7
13 -18 20 -11 45 10 36 21 -8 26 6 9 26 -13 14 -14 21 -5 32 13 16 1 51 -20
58 -5 2 -13 23 -16 47 -5 34 -2 46 11 53 20 11 22 58 1 58 -23 0 -19 9 24 50
27 26 43 35 52 29 8 -5 21 -9 29 -9 12 1 11 3 -2 11 -26 15 -23 55 5 64 l22 8
-22 4 c-20 4 -27 14 -25 35 3 17 -29 15 -38 -2 -6 -11 -15 -20 -21 -20 -19 0
-21 31 -4 50 10 11 14 20 9 20 -15 -1 -44 -26 -44 -38 0 -15 -38 -35 -50 -27
-16 10 -11 23 12 31 l22 7 -22 17 c-26 21 -29 50 -6 50 11 0 15 6 11 20 -6 22
1 24 37 10 16 -6 27 -6 31 0 9 15 35 12 35 -4 0 -13 -51 -31 -79 -27 -7 1 -11
-4 -9 -11 3 -8 20 -13 44 -13 35 0 40 3 49 33 13 44 40 44 31 0 -3 -18 -12
-36 -19 -40 -7 -5 0 -8 19 -8 40 0 61 22 39 40 -14 12 -14 15 1 31 14 15 14
21 4 34 -7 8 -21 15 -31 15 -10 0 -21 9 -24 21 -7 20 -7 20 -26 -3 -18 -20
-22 -21 -41 -9 -20 12 -24 11 -44 -15 -25 -31 -28 -30 -39 4 -5 19 -2 25 14
29 28 7 27 39 -1 48 -20 6 -18 8 15 15 59 11 70 30 19 30 -23 0 -42 -4 -42
-10 0 -5 -7 -10 -15 -10 -19 0 -21 -56 -2 -63 9 -4 9 -6 0 -6 -17 -1 -17 -68
0 -74 9 -4 9 -7 0 -18 -10 -10 -13 -615 -13 -2956 l0 -2943 5120 0 5120 0 0
5120 0 5120 -633 0 -633 -1 4 -30 c2 -16 0 -29 -5 -29 -6 0 -10 -13 -11 -30
-1 -28 2 -30 38 -30 21 0 40 4 42 10 5 15 158 26 158 11 0 -6 -17 -12 -40 -12
-60 -2 -60 -2 -80 -29 -16 -20 -22 -22 -29 -12 -12 19 -54 -7 -45 -28 3 -8 7
-33 8 -54 0 -22 7 -41 14 -43 6 -3 12 1 12 8 0 18 31 37 52 31 10 -2 16 -10
13 -16 -3 -6 -8 -18 -11 -27 -3 -9 -15 -21 -27 -27 -12 -6 -58 -28 -102 -49
-44 -21 -81 -41 -83 -45 -2 -5 -13 -8 -24 -8 -11 0 -32 -7 -46 -15 -15 -8 -79
-30 -142 -50 -63 -19 -136 -42 -161 -50 -26 -8 -58 -15 -71 -15 -13 0 -47 -7
-75 -15 -65 -19 -256 -52 -353 -60 -41 -4 -91 -11 -111 -16 -20 -5 -47 -9 -60
-8 -13 0 -108 -2 -211 -5 -111 -4 -242 -2 -320 5 -259 22 -662 60 -888 85
-400 43 -1095 109 -1415 135 -207 16 -215 17 -280 24 -115 11 -691 43 -825 44
-73 1 -137 4 -141 7 -11 6 -751 6 -959 -1 -102 -3 -225 -10 -275 -15 -49 -5
-124 -10 -165 -10 -41 0 -115 -6 -165 -14 -49 -7 -117 -12 -150 -11 -33 2 -68
-1 -77 -6 -10 -5 -53 -12 -95 -14 -43 -3 -100 -9 -128 -14 -210 -39 -284 -45
-314 -26 -7 4 -18 0 -25 -10 -7 -10 -19 -16 -27 -13 -8 3 -27 1 -44 -4 -102
-32 -128 -36 -153 -27 -21 8 -31 6 -54 -11 -50 -37 -156 -34 -119 3 17 17 13
25 -16 31 -16 2 8 3 52 1 48 -2 74 0 65 5 -12 8 -12 10 2 15 20 8 41 -2 33
-16 -11 -16 3 -10 30 15 l25 23 -30 6 c-16 4 -30 13 -30 21 0 7 -9 16 -20 19
-29 8 -24 21 13 32 48 15 70 8 64 -21 -3 -17 1 -25 14 -29 11 -4 19 -15 19
-26 0 -12 7 -19 19 -19 43 0 51 10 51 65 l0 52 53 -1 52 -1 -3 -34 c-2 -26 2
-34 18 -38 11 -3 20 -9 20 -14 0 -5 4 -9 8 -9 10 0 10 46 0 56 -5 4 -8 13 -8
21 0 16 61 18 77 2 8 -8 21 -7 47 2 41 15 45 28 14 48 -18 11 -22 11 -30 -3
-13 -24 -28 -19 -28 9 0 33 -35 68 -61 60 -10 -4 -19 -13 -19 -21 0 -8 -5 -12
-10 -9 -6 4 -17 2 -24 -4 -19 -16 -46 -3 -46 23 -1 19 -2 19 -13 4 -7 -9 -17
-24 -22 -33 -17 -28 -75 -11 -75 21 0 15 6 24 15 24 8 0 15 4 15 10 0 5 -7 7
-15 4 -28 -11 -46 16 -41 60 2 23 -1 50 -7 61 -7 13 -7 20 1 23 8 2 9 9 2 21
-6 13 -5 24 5 37 13 18 14 18 20 1 4 -9 5 -26 2 -37 -3 -11 -1 -20 4 -20 5 0
6 -13 3 -28 -6 -27 -3 -30 39 -42 34 -9 47 -18 51 -36 8 -31 24 -31 32 1 3 14
10 25 16 25 5 0 7 -3 5 -7 -3 -5 -1 -19 4 -32 8 -20 14 -22 47 -18 20 3 47 8
59 12 16 5 23 2 28 -14 9 -28 25 -27 25 2 0 31 35 64 52 50 18 -14 67 -22 74
-12 3 5 18 9 34 9 15 0 31 6 33 13 3 7 6 5 6 -5 1 -10 5 -18 10 -18 18 0 20
16 5 44 -16 33 -19 82 -4 91 5 3 10 15 10 26 0 19 -12 19 -490 19 -295 0 -490
-4 -490 -9 0 -6 -12 -6 -32 0 -18 5 -198 9 -400 9 l-368 0 0 -900z m9057 836
c-4 -10 -18 -16 -37 -16 -18 0 -29 4 -25 10 3 6 15 10 26 10 11 0 19 7 19 17
0 13 3 14 11 6 7 -7 9 -19 6 -27z m-7318 -4 c11 -21 -2 -48 -18 -38 -14 9 -15
56 -1 56 5 0 14 -8 19 -18z m-617 -43 c-4 -28 -6 -30 -21 -14 -15 15 -15 17 3
31 11 8 20 14 21 14 1 0 -1 -14 -3 -31z m66 -32 c2 -11 -3 -17 -16 -17 -15 0
-19 5 -15 20 6 24 26 21 31 -3z m8016 -18 c15 -14 -25 -23 -45 -11 -26 16 -37
15 -42 -5 -3 -12 -5 -10 -6 7 -1 24 1 25 42 22 23 -2 46 -8 51 -13z m-64 -94
c0 -8 -4 -17 -10 -20 -6 -4 -10 5 -10 20 0 15 4 24 10 20 6 -3 10 -12 10 -20z
m340 -108 c0 -7 9 -18 20 -25 11 -7 19 -22 19 -35 l-2 -22 -4 23 c-6 28 -32
29 -62 1 l-23 -21 24 -12 c16 -8 22 -19 20 -34 -2 -16 2 -22 16 -22 20 0 32
-18 32 -50 0 -10 9 -20 20 -23 11 -3 20 -14 20 -24 0 -10 5 -24 11 -30 7 -7 9
-25 5 -47 -6 -30 -11 -35 -39 -38 l-32 -3 40 -24 40 -25 -38 -6 c-46 -8 -53
-15 -37 -35 11 -14 10 -20 -12 -40 -18 -17 -24 -31 -21 -48 3 -14 0 -35 -7
-47 -11 -20 -10 -21 10 -15 25 8 60 -19 60 -47 0 -11 7 -18 20 -18 13 0 18 -5
14 -14 -5 -12 2 -30 33 -85 1 -2 14 4 29 14 19 12 29 14 37 6 8 -8 7 -11 -3
-11 -11 -1 -10 -3 3 -11 14 -8 16 -14 6 -25 -6 -8 -20 -14 -30 -14 -11 0 -19
-7 -19 -16 0 -12 7 -15 30 -12 36 5 45 -4 29 -31 -10 -15 -9 -22 4 -36 10 -10
17 -32 17 -55 0 -21 5 -40 10 -42 13 -4 31 -144 34 -253 1 -44 7 -89 14 -101
6 -11 11 -29 11 -40 0 -10 3 -44 6 -74 10 -96 12 -122 19 -245 4 -66 9 -145
12 -175 6 -68 5 -497 -1 -890 -5 -283 -25 -651 -45 -810 -6 -41 -10 -104 -10
-140 1 -36 -6 -87 -14 -115 -9 -27 -13 -64 -10 -82 5 -26 2 -32 -15 -38 -22
-7 -30 -27 -8 -22 8 1 12 -13 11 -48 -2 -95 -24 -350 -34 -390 -5 -22 -12 -76
-15 -120 -5 -88 -17 -203 -39 -370 -8 -60 -20 -150 -26 -200 -7 -49 -19 -130
-27 -180 -8 -49 -21 -148 -28 -220 -16 -157 -34 -263 -46 -271 -5 -3 -9 -33
-9 -67 0 -40 -6 -70 -15 -84 -10 -14 -12 -24 -5 -28 13 -8 13 -52 -1 -61 -6
-3 -9 -18 -6 -32 3 -14 -2 -45 -10 -69 -8 -24 -13 -58 -10 -76 7 -49 -30 -233
-59 -290 -12 -24 -12 -29 1 -33 8 -4 15 -15 15 -25 0 -22 -29 -26 -49 -6 -11
10 -13 6 -7 -22 3 -18 6 -53 6 -77 0 -33 4 -44 16 -44 13 0 14 -8 8 -47 -4
-27 -11 -49 -15 -51 -5 -2 -2 -14 7 -27 13 -19 15 -57 12 -219 -1 -108 -6
-196 -9 -196 -4 0 -5 -9 -2 -20 4 -15 0 -20 -16 -20 -26 0 -27 -11 -2 -29 21
-16 17 -23 -33 -55 -29 -18 -30 -18 -43 0 -10 14 -28 19 -65 20 -34 1 -54 7
-56 16 -3 7 -11 21 -19 30 -9 10 -12 25 -8 37 5 16 2 21 -13 21 -18 0 -19 -3
-7 -37 10 -28 10 -43 2 -58 -6 -11 -10 -25 -9 -32 5 -23 -18 -24 -27 -1 -7 18
-10 20 -15 8 -4 -9 -5 -26 -4 -39 2 -13 -3 -26 -9 -28 -8 -3 -13 4 -13 16 0
23 -22 41 -28 23 -2 -7 -19 -12 -38 -12 -32 0 -35 2 -32 28 2 19 -2 27 -12 27
-9 0 -15 -9 -15 -21 0 -12 -8 -31 -19 -42 -29 -33 -16 -43 30 -23 47 21 64 14
64 -26 0 -13 6 -23 13 -23 27 0 -19 -20 -48 -20 -16 0 -36 -4 -44 -9 -15 -10
-41 1 -79 34 -20 17 -24 17 -32 5 -6 -8 -8 -21 -5 -27 5 -14 -38 -29 -53 -19
-18 11 -102 -6 -102 -20 0 -8 6 -11 15 -8 16 6 85 -14 84 -24 -2 -23 -39 -67
-60 -73 -23 -7 -23 -7 -2 -8 12 -1 24 -5 27 -10 5 -8 30 -9 61 -1 36 9 49 -14
14 -25 -16 -5 -19 -12 -15 -30 7 -26 -8 -42 -21 -23 -4 7 -6 23 -4 36 2 22
-13 30 -24 12 -3 -5 -20 -10 -36 -10 -19 0 -32 -6 -36 -18 -4 -9 -14 -23 -22
-29 -13 -11 -8 -13 34 -13 64 0 107 -4 120 -11 6 -4 22 -9 38 -13 31 -7 36
-34 12 -80 -8 -15 -15 -36 -16 -45 0 -10 -2 -11 -6 -3 -2 6 -9 10 -13 7 -5 -3
-7 -12 -4 -20 4 -8 -3 -15 -17 -17 -12 -2 -24 -9 -27 -17 -3 -9 1 -11 15 -7
13 4 26 -1 40 -17 29 -34 32 -48 16 -81 -8 -15 -13 -37 -10 -47 3 -10 2 -18
-2 -17 -14 3 -41 -15 -41 -28 0 -16 30 -33 47 -27 6 2 14 -1 18 -6 4 -7 -10
-11 -39 -11 -39 0 -46 -3 -46 -19 0 -32 32 -41 103 -26 31 6 37 5 37 -10 0
-15 -6 -17 -41 -11 -33 5 -40 4 -36 -7 9 -24 -11 -42 -45 -42 l-33 0 -1 -53
-1 -54 -47 -3 c-48 -3 -60 5 -61 40 0 17 -7 20 -50 20 -38 0 -50 -4 -50 -15 0
-10 11 -15 35 -15 28 0 35 -4 35 -20 0 -30 14 -46 37 -43 12 2 23 -3 26 -11 3
-10 -2 -12 -20 -8 -18 5 -35 -1 -58 -18 -38 -28 -96 -35 -86 -10 3 9 0 15 -8
15 -7 0 -27 3 -43 6 -24 5 -31 2 -40 -18 -13 -30 9 -57 32 -38 11 9 19 8 36
-3 l21 -15 -21 -11 c-15 -9 -27 -9 -41 -1 -11 6 -20 7 -20 2 0 -6 -9 -12 -20
-15 -22 -6 -48 17 -30 28 5 3 10 33 10 66 0 45 -4 61 -16 66 -8 3 -13 12 -10
20 7 18 -14 41 -32 35 -6 -2 -12 -12 -12 -23 0 -16 -7 -19 -44 -19 -24 0 -48
-6 -54 -12 -13 -17 -25 -150 -17 -188 4 -16 15 -32 25 -35 10 -3 21 -16 25
-28 6 -19 8 -19 12 -4 2 9 12 17 21 17 13 0 14 -2 4 -9 -10 -6 -9 -10 8 -14
17 -4 20 -10 14 -31 -5 -22 -3 -26 17 -26 13 0 34 -3 47 -6 19 -6 22 -11 17
-33 -4 -14 -15 -35 -26 -47 -10 -11 -19 -28 -19 -37 0 -9 -6 -17 -14 -17 -19
0 -31 -39 -17 -53 7 -7 15 -6 25 2 8 7 20 10 26 6 15 -9 12 -45 -5 -51 -22 -9
-18 -24 6 -24 12 0 19 -5 16 -12 -2 -7 -15 -12 -29 -10 -13 2 -30 -4 -38 -12
-10 -13 -9 -17 11 -25 13 -5 27 -7 32 -4 4 2 16 1 27 -2 28 -9 13 -28 -16 -21
-13 4 -24 2 -24 -3 0 -5 -9 -13 -21 -16 -36 -12 -59 20 -61 84 l-2 56 -3 -55
c-5 -64 -17 -82 -52 -79 -14 1 -34 -2 -45 -6 -22 -10 -38 11 -33 42 2 13 -2
28 -9 34 -17 14 -87 14 -92 0 -2 -6 -14 -8 -28 -5 -21 5 -24 3 -24 -20 0 -20
5 -26 21 -26 11 0 17 -4 14 -10 -12 -19 -44 -10 -65 20 -12 17 -26 30 -32 30
-20 0 -47 -31 -41 -48 4 -13 3 -14 -5 -4 -13 19 -82 5 -82 -17 0 -9 9 -22 20
-29 21 -13 27 -65 11 -91 -6 -10 -12 -3 -21 23 -11 35 -23 40 -26 13 -7 -65
-46 -79 -42 -15 3 50 -21 65 -39 25 -9 -19 -21 -25 -60 -29 -26 -3 -55 -9 -64
-12 -20 -9 -45 35 -34 61 8 17 8 17 12 0 5 -19 23 -14 23 7 0 13 -12 19 -79
36 -39 10 -81 -3 -81 -24 0 -17 -22 -26 -66 -26 -32 0 -34 -2 -34 -35 0 -24 5
-35 15 -35 8 0 12 6 9 14 -7 18 25 36 49 27 17 -6 17 -8 -2 -25 -11 -10 -17
-24 -14 -32 4 -11 -9 -14 -64 -14 -38 0 -72 3 -75 6 -12 11 9 59 30 69 19 8
15 11 -31 25 -56 18 -79 16 -71 -5 3 -7 -5 -15 -21 -19 -20 -5 -25 -11 -20
-26 5 -16 2 -20 -19 -20 -22 0 -26 4 -26 30 0 19 -5 30 -14 30 -21 0 -30 -20
-17 -33 18 -18 13 -27 -19 -27 -20 0 -30 -5 -30 -15 0 -18 -70 -21 -88 -3 -8
8 -15 8 -24 1 -17 -15 -91 -14 -100 0 -6 9 -8 9 -8 0 0 -18 -29 -16 -36 2 -8
20 -30 19 -38 -1 -4 -10 -13 -13 -26 -9 -12 4 -27 2 -35 -5 -9 -7 -54 -9 -142
-6 -70 3 -234 8 -363 11 -129 4 -275 11 -325 16 -49 5 -112 10 -140 10 -27 0
-104 5 -170 10 -66 5 -164 11 -218 14 -54 2 -101 7 -106 9 -4 3 -46 8 -92 11
-217 14 -362 26 -449 40 -52 7 -135 16 -185 20 -53 3 -94 10 -100 18 -7 8 -50
12 -145 12 -74 1 -171 4 -215 7 -44 3 -132 9 -195 13 -63 4 -119 11 -125 14
-5 4 -40 8 -78 10 -37 1 -96 10 -130 20 -52 14 -65 15 -76 4 -6 -7 -18 -13
-26 -13 -21 0 -19 37 2 49 24 13 11 23 -20 16 -16 -4 -27 -15 -29 -28 -3 -24
3 -24 -150 -12 l-88 7 0 29 c0 36 -17 38 -30 4 -10 -26 -24 -32 -35 -15 -4 7
-12 7 -25 0 -10 -5 -27 -9 -37 -9 -17 1 -17 2 -1 6 23 6 23 20 -1 33 -26 14
-31 13 -31 -6 0 -10 -6 -14 -14 -11 -7 3 -17 2 -22 -3 -5 -4 -29 -5 -54 -1
-42 6 -45 8 -48 39 -2 19 1 32 8 32 6 0 9 4 6 9 -3 5 1 25 9 45 l15 36 -26 0
c-23 0 -25 -3 -20 -26 4 -14 4 -35 0 -46 -6 -20 -6 -20 -21 1 -8 12 -12 30 -8
41 4 14 2 20 -8 20 -8 0 -23 19 -32 42 -14 34 -19 39 -24 25 -4 -10 -8 20 -9
67 -2 71 0 86 13 86 8 0 15 5 15 10 0 6 -6 10 -12 10 -9 0 -8 4 2 10 11 8 8 9
-12 4 -23 -5 -28 -3 -28 12 0 14 -3 15 -12 6 -14 -14 -38 -16 -38 -3 0 10 39
27 52 23 8 -2 217 -13 253 -13 166 -1 165 -1 165 26 0 14 -4 25 -10 25 -6 0
-10 27 -10 60 0 45 4 60 14 60 8 0 21 3 30 6 13 5 16 -8 18 -88 l3 -93 35 3
c46 5 58 17 37 40 -12 14 -17 37 -17 88 0 38 -4 75 -9 83 -9 14 -163 33 -188
24 -14 -6 -18 -53 -4 -53 17 0 21 -91 5 -125 -15 -32 -16 -33 -34 -15 -14 14
-18 34 -18 100 -1 45 -3 84 -5 87 -3 2 -26 5 -52 7 -41 2 -50 6 -57 27 -8 22
-10 22 -19 7 -6 -10 -21 -18 -34 -18 -24 0 -25 3 -25 56 l0 55 -47 -3 c-52 -3
-67 -23 -38 -51 11 -12 15 -37 14 -104 0 -79 -15 -205 -18 -160 0 10 -8 20
-17 24 -13 5 -15 19 -10 90 6 92 8 89 -60 96 -39 4 -40 3 -36 -21 l5 -26 -28
22 c-23 18 -41 22 -101 22 -53 0 -74 -4 -74 -12 0 -9 -2 -10 -8 -2 -4 6 -18
15 -32 19 -13 4 -30 14 -38 21 -7 8 -20 14 -28 14 -8 0 -14 9 -14 20 0 11 7
20 15 20 18 0 19 12 3 28 -9 9 -9 15 0 24 22 22 13 38 -19 38 -21 0 -40 -9
-55 -26 -30 -31 -30 -34 0 -34 21 0 36 -16 36 -40 0 -4 -13 -10 -30 -14 -23
-4 -30 -11 -30 -28 0 -13 -3 -18 -8 -12 -12 18 -52 36 -67 30 -8 -3 -17 -1
-20 4 -4 6 -20 10 -36 10 -40 0 -33 25 7 26 16 1 39 6 51 13 12 6 31 11 42 11
22 0 25 8 15 34 -10 26 -41 19 -52 -11 -6 -16 -11 -22 -11 -15 -1 6 -8 12 -17
12 -13 0 -15 7 -10 30 4 23 2 30 -11 30 -21 0 -42 -29 -43 -57 0 -13 -6 -23
-14 -23 -9 0 -12 7 -9 19 3 10 0 22 -5 25 -6 3 -8 16 -4 28 3 13 1 19 -6 15
-5 -4 -25 11 -42 33 -21 27 -40 40 -56 40 -13 0 -24 5 -24 11 0 8 -13 9 -40 5
-31 -5 -40 -3 -40 8 0 8 10 16 23 18 14 2 21 9 19 20 -1 10 4 28 13 42 14 22
14 25 0 40 -9 9 -21 16 -26 16 -5 0 -9 5 -9 11 0 12 -60 69 -72 69 -5 0 -8 16
-8 36 0 25 -7 43 -21 55 -19 16 -20 20 -6 34 9 8 18 15 22 15 4 0 13 6 20 14
12 12 12 16 1 25 -8 7 -17 23 -20 36 -4 14 -13 25 -21 25 -9 0 -15 9 -15 23 0
32 10 40 26 19 12 -16 13 -15 14 6 0 15 -8 26 -26 32 -21 8 -25 15 -20 34 4
16 1 26 -8 29 -17 7 -44 59 -52 103 -7 38 -7 38 -35 24 -25 -14 -24 -28 4 -32
12 -2 22 -10 22 -18 0 -11 -12 -15 -42 -16 -23 -1 -55 -10 -70 -20 -27 -18
-27 -19 -5 -22 21 -3 32 -22 13 -22 -5 0 -16 -2 -24 -6 -10 -3 -18 8 -27 40
-11 41 -15 45 -36 40 -17 -4 -30 1 -47 20 -13 14 -29 26 -36 26 -6 0 -11 11
-11 25 0 23 -3 25 -27 19 -16 -4 -34 -11 -40 -16 -9 -7 -16 -3 -24 15 -8 17
-17 23 -30 20 -24 -7 -24 -18 1 -43 13 -13 20 -33 20 -60 0 -34 -3 -40 -21
-40 -13 0 -23 8 -26 20 l-6 21 -19 -21 c-24 -26 -58 -26 -65 0 -5 20 -9 22
-53 24 -18 1 -24 6 -22 18 4 27 -16 58 -33 52 -9 -4 -22 -1 -30 6 -8 7 -21 10
-28 7 -7 -3 -21 1 -32 9 -17 12 -17 14 -2 14 10 0 17 8 17 20 0 24 9 26 25 3
13 -18 40 -9 28 10 -4 7 -6 24 -4 39 1 15 -3 37 -9 49 -10 19 -8 25 14 46 20
18 26 20 26 8 0 -18 2 -18 35 -3 29 13 30 17 12 42 -12 16 -14 16 -26 2 -8 -9
-17 -16 -20 -16 -4 0 -6 60 -6 132 1 73 2 211 3 306 l2 172 40 0 c27 0 47 -7
61 -21 21 -21 22 -25 15 -293 -6 -228 -9 -273 -23 -288 -9 -10 -14 -20 -12
-23 8 -7 296 -8 308 -1 6 4 11 0 11 -9 0 -9 5 -13 10 -10 7 4 5 14 -5 28 -12
18 -14 64 -9 311 3 160 8 294 11 299 12 20 95 -5 114 -35 5 -7 7 -65 4 -128
-12 -279 -14 -450 -5 -455 6 -3 67 -6 137 -6 l128 -1 3 138 c1 76 0 136 -3
133 -3 -3 -6 -56 -7 -117 0 -61 -4 -116 -9 -123 -5 -9 -23 -1 -64 31 l-56 43
2 251 c1 160 -3 261 -9 278 -6 15 -30 41 -52 57 -33 23 -55 31 -113 36 -92 8
-212 2 -180 -9 13 -5 23 -11 23 -15 0 -4 13 -15 30 -24 l30 -18 -1 -192 c-1
-106 -4 -244 -8 -307 -7 -131 -11 -135 -83 -77 l-40 32 6 274 c6 313 2 334
-55 344 -48 9 -309 10 -317 2 -4 -4 3 -14 15 -22 12 -8 32 -23 44 -34 12 -10
26 -19 31 -19 6 0 7 -35 5 -82 -3 -46 -6 -119 -6 -163 -1 -44 -4 -97 -7 -118
-5 -31 -8 -36 -20 -26 -11 9 -14 7 -14 -12 0 -20 -2 -21 -11 -9 -8 12 -9 10
-7 -9 4 -33 22 -37 35 -9 10 22 11 21 18 -14 9 -48 -1 -83 -21 -67 -11 9 -14
8 -14 -5 0 -9 -4 -16 -9 -16 -12 0 -18 19 -27 83 -4 30 -11 47 -20 47 -16 0
-19 36 -4 45 6 4 4 15 -6 30 -9 13 -13 29 -9 35 4 7 6 75 5 153 -1 78 -2 158
-1 177 1 28 -7 44 -34 73 -19 20 -42 37 -50 37 -8 0 -15 6 -15 14 0 12 56 14
372 11 205 -1 344 1 308 5 -36 4 -144 9 -240 10 -307 4 -383 8 -443 25 -17 4
-33 3 -42 -5 -12 -10 -18 -10 -31 1 -9 8 -27 12 -40 11 -13 -2 -24 1 -24 7 0
6 14 11 30 11 38 0 40 25 3 35 -16 4 -34 11 -41 17 -19 16 5 39 34 32 17 -4
24 -2 24 8 0 19 -20 38 -42 38 -10 0 -18 6 -18 14 0 17 26 40 38 32 5 -3 14 6
21 21 12 27 3 42 -25 43 -6 0 -17 7 -24 15 -7 8 -15 13 -19 11 -3 -2 -4 21 -2
52 1 32 8 60 14 64 7 4 7 11 -3 22 -11 14 -10 19 4 35 11 12 14 27 10 49 -5
22 0 41 16 66 l22 36 -21 0 c-25 0 -27 22 -6 44 18 18 19 36 3 36 -8 1 -7 6 4
14 13 9 16 25 14 62 -3 55 7 84 30 84 9 0 14 5 12 11 -2 6 -8 11 -13 11 -25 0
-32 21 -29 81 9 172 46 534 84 827 5 36 18 139 29 228 11 90 23 178 26 195 2
18 7 59 10 92 4 33 11 94 16 135 5 41 14 109 20 150 17 131 29 233 39 320 20
184 39 349 51 435 10 83 8 414 -4 520 -3 22 -8 85 -11 140 -4 55 -14 145 -22
200 -35 235 -56 353 -70 395 -8 25 -14 55 -14 66 0 12 -7 38 -15 57 -8 20 -15
49 -15 65 0 15 -4 32 -9 37 -10 11 -32 88 -56 195 -8 36 -19 76 -25 90 -6 14
-12 48 -14 75 -2 28 -7 58 -11 68 -4 9 -3 23 3 30 13 15 3 69 -12 64 -6 -2
-14 -17 -17 -33 -5 -26 -7 -27 -13 -9 -4 11 -13 30 -19 43 -8 18 -7 25 7 35
15 11 16 17 5 39 -14 33 -6 58 20 58 21 0 33 22 25 45 -9 22 -24 18 -24 -5 0
-13 -7 -20 -19 -20 -11 0 -22 -8 -24 -17 -4 -16 -5 -16 -6 -1 0 9 4 20 9 23
15 9 12 41 -5 55 -17 14 -12 25 13 25 10 0 16 7 16 18 -1 9 4 17 11 17 7 0 20
3 29 6 11 4 16 0 17 -12 0 -15 2 -15 6 -4 14 37 95 82 173 97 14 3 68 18 120
33 52 15 122 33 155 38 77 14 105 20 185 37 36 8 112 23 170 35 58 11 125 25
150 30 25 6 63 12 85 15 22 3 60 7 85 10 25 3 63 9 85 15 22 5 58 11 80 14 22
4 71 11 109 16 75 11 406 45 611 62 75 6 384 11 735 11 588 0 827 -8 1300 -43
74 -6 203 -15 285 -21 83 -6 191 -14 240 -20 50 -5 227 -22 395 -39 582 -57
1167 -119 1194 -126 12 -2 68 -9 126 -14 58 -5 130 -11 160 -15 30 -3 64 -6
75 -7 11 0 45 -4 77 -9 122 -20 602 -8 858 22 108 13 425 64 485 79 22 5 69
16 105 23 64 14 92 22 240 73 41 14 89 29 106 33 18 5 37 12 44 16 21 13 174
65 191 65 9 0 27 11 41 25 30 30 74 47 101 39 12 -4 17 -2 13 4 -6 11 51 32
87 32 11 0 17 8 17 25 0 16 6 25 15 25 8 0 15 -6 15 -13z m-8420 -17 c0 -13
-28 -25 -38 -16 -3 4 0 11 8 16 20 13 30 12 30 0z m-80 -34 c0 -8 -4 -18 -10
-21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z m-100 -76 c0 -5 -9
-10 -19 -10 -30 0 -33 -15 -11 -43 24 -30 13 -41 -23 -24 -30 14 -41 52 -20
73 16 16 73 19 73 4z m66 -4 c16 -17 19 -36 4 -36 -5 0 -10 7 -10 15 0 7 -8
19 -17 24 -12 7 -13 11 -5 11 7 0 20 -6 28 -14z m-26 -57 c0 -4 -9 -13 -20
-19 -17 -9 -20 -8 -20 14 0 19 4 23 20 19 11 -3 20 -9 20 -14z m-161 -10 c-16
-10 -23 -4 -14 10 3 6 11 8 17 5 6 -4 5 -9 -3 -15z m46 -44 c0 -5 -17 -11 -37
-13 -36 -3 -47 5 -31 22 11 10 68 3 68 -9z m8845 -443 c0 -5 -11 -19 -25 -32
l-25 -23 0 21 c0 13 6 22 15 22 8 0 15 5 15 10 0 6 5 10 10 10 6 0 10 -4 10
-8z m-9550 -236 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
10 -2 10 -4z m9857 -188 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-9587 -39 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10
10 -16z m9650 2 c0 -8 -60 -42 -60 -33 0 4 -3 16 -6 28 -5 18 -2 20 30 16 20
-2 36 -7 36 -11z m-7 -56 c-3 -9 0 -22 7 -30 12 -15 6 -35 -11 -35 -5 0 -9 21
-9 46 0 26 4 43 10 39 5 -3 7 -12 3 -20z m-24 -57 c0 -13 -4 -32 -8 -43 -7
-16 -9 -11 -12 23 -3 29 -1 42 8 42 7 0 13 -10 12 -22z m20 -137 c7 -5 9 -11
5 -14 -5 -3 -15 1 -22 9 -14 16 -5 18 17 5z m3 -82 c6 -4 5 -16 -4 -34 l-15
-28 -6 48 c-4 26 -4 52 1 59 4 7 8 1 10 -14 2 -14 9 -28 14 -31z m40 -76 c-9
-4 -10 -8 -2 -18 7 -9 8 -20 2 -32 -24 -47 -32 -70 -30 -101 1 -30 -7 -51 -16
-41 -2 2 -6 28 -8 57 -4 46 -3 52 13 52 25 0 24 12 -1 35 -11 10 -20 25 -20
34 0 13 2 14 9 2 8 -12 12 -12 25 -2 9 8 16 21 16 29 0 13 2 14 13 3 10 -11 9
-14 -1 -18z m15 -703 c3 -11 12 -20 19 -20 8 0 14 -7 14 -15 0 -8 -9 -15 -19
-15 -11 0 -23 -4 -26 -10 -3 -5 -10 -10 -14 -10 -10 0 -20 60 -14 78 8 19 34
14 40 -8z m-17 -90 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m-16 -177 c-10 -40 -14 -41 -14 -5 0 18 5 32 10 32 6 0 8 -11
4 -27z m50 -77 c-7 -28 -34 -36 -34 -11 0 8 7 15 15 15 8 0 15 8 15 17 0 9 2
14 5 11 4 -3 3 -17 -1 -32z m-29 -96 c-19 -21 -35 -18 -35 6 0 9 10 14 27 14
25 0 26 -1 8 -20z m37 -78 c-4 -11 -8 -11 -20 1 -18 19 -7 42 12 24 8 -6 11
-18 8 -25z m-52 -218 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6
0 10 -7 10 -16z m74 -23 c16 -18 16 -20 -11 -40 -15 -11 -22 -21 -14 -21 19 0
-33 -40 -52 -40 -22 0 -32 -19 -18 -33 9 -9 14 -9 19 0 5 8 19 9 45 3 21 -4
46 -6 57 -3 11 3 20 1 20 -5 0 -17 -25 -31 -70 -38 -75 -11 -98 -44 -31 -44
l40 0 -2 -57 c-3 -67 -22 -103 -55 -103 -13 0 -20 -4 -17 -10 4 -6 10 -8 15
-5 14 9 26 -26 26 -70 -1 -29 -5 -41 -18 -43 -14 -3 -17 3 -15 30 2 39 -13 50
-29 18 -11 -21 -12 -20 -19 19 -4 22 -4 44 -1 49 3 5 7 87 11 182 3 95 7 175
10 178 2 3 16 -3 30 -12 33 -22 45 -13 45 30 0 38 9 43 34 15z m96 -272 c0 -5
-7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m-53 -25 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m48 -33 c3 -5 1 -12 -5 -16 -5 -3 -10
1 -10 9 0 18 6 21 15 7z m-190 -268 c-4 -10 -7 -28 -6 -40 4 -35 -26 -28 -34
8 -5 22 -2 36 11 50 20 23 41 10 29 -18z m51 9 c-9 -9 -37 8 -31 18 5 8 11 7
21 -1 8 -6 12 -14 10 -17z m144 -46 c0 -16 -18 -31 -27 -22 -8 8 5 36 17 36 5
0 10 -6 10 -14z m-213 -76 c-5 -20 -2 -32 8 -38 13 -6 13 -13 2 -41 -10 -28
-10 -34 6 -42 29 -17 19 -29 -23 -29 l-40 0 0 43 c0 24 7 52 15 63 8 10 15 31
15 47 0 15 5 27 11 27 7 0 9 -11 6 -30z m159 -117 c-10 -10 -19 5 -10 18 6 11
8 11 12 0 2 -7 1 -15 -2 -18z m-142 -67 c38 -1 44 -30 6 -32 -55 -2 -58 -5
-41 -32 7 -12 12 -12 29 -1 43 27 41 -25 -3 -76 -29 -33 -32 -90 -5 -90 25 0
25 -44 0 -53 -22 -8 -22 -8 4 -26 20 -13 25 -22 21 -37 -4 -13 0 -28 11 -40
16 -18 16 -19 -9 -19 -29 0 -37 -24 -12 -34 9 -3 15 -18 15 -40 0 -27 -3 -34
-15 -30 -8 4 -15 12 -15 20 0 17 -5 17 -30 2 -16 -11 -22 -9 -34 7 -10 12 -15
15 -16 7 0 -7 -4 -11 -8 -8 -10 6 -8 173 3 199 5 12 11 15 20 7 6 -5 15 -7 18
-3 14 13 6 38 -17 58 l-24 20 14 95 c18 117 19 121 40 113 9 -3 30 -6 48 -7z
m52 -171 c-9 -9 -16 -21 -16 -26 0 -5 -4 -9 -10 -9 -19 0 -10 37 13 47 28 13
34 8 13 -12z m-143 -371 c-6 -17 -19 -18 -29 -2 -4 6 -2 14 4 16 7 2 9 9 5 15
-4 7 0 7 9 -1 9 -8 14 -20 11 -28z m99 4 c-9 -9 -15 -9 -24 0 -9 9 -7 12 12
12 19 0 21 -3 12 -12z m-128 -75 c-9 -52 -14 -54 -14 -5 0 23 5 42 10 42 7 0
8 -14 4 -37z m243 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-129 -36 c23 -23 14 -38 -20 -32 -31 5 -32 5 -14 -10 11 -9 26 -16 33 -16 9
0 11 -8 7 -24 -3 -13 -1 -27 5 -30 6 -4 8 -20 4 -38 -5 -23 -2 -34 12 -44 17
-13 17 -14 -1 -19 -37 -12 -49 -22 -52 -46 -1 -13 -5 -45 -8 -71 -5 -40 -3
-48 10 -48 9 0 16 -8 16 -17 0 -15 -2 -16 -10 -3 -17 27 -45 -1 -42 -42 2 -22
-2 -39 -12 -47 -9 -8 -16 -17 -16 -22 0 -17 50 -9 70 11 17 17 20 17 20 4 0
-9 -9 -18 -20 -21 -16 -4 -18 -9 -10 -24 9 -15 15 -17 37 -8 14 5 27 8 29 7 1
-2 0 -22 -3 -46 l-6 -42 -25 25 c-25 25 -62 34 -62 15 0 -5 5 -10 10 -10 27 0
4 -33 -45 -65 -62 -39 -80 -44 -71 -17 5 14 4 14 -9 -2 -18 -23 -19 -33 -5
-41 6 -3 10 -22 10 -41 0 -26 -7 -41 -27 -58 -24 -21 -26 -26 -14 -40 8 -9 20
-15 28 -12 21 8 15 -4 -13 -26 -14 -11 -23 -27 -20 -34 3 -8 4 -14 2 -14 -3 0
-11 -3 -20 -6 -12 -4 -16 0 -16 15 0 12 5 21 11 21 8 0 8 4 1 13 -14 18 -8 69
14 105 15 27 16 34 5 41 -14 9 -11 82 8 185 5 27 7 55 4 62 -3 7 0 28 6 46 5
18 12 60 15 93 3 33 10 92 16 130 5 39 12 90 15 115 11 83 14 90 40 90 14 0
25 6 25 13 0 7 12 18 28 24 37 15 46 15 60 1z m52 -332 c0 -3 -4 -8 -10 -11
-5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-9940 -152 c0 -8 -4 -12
-10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-84 -111 c-10 -10
-19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m149 -109 c13 -5 14 -8 3 -15
-19 -13 -28 -11 -28 6 0 17 2 18 25 9z m9740 -13 c3 -5 1 -12 -5 -16 -5 -3
-10 1 -10 9 0 18 6 21 15 7z m-35 -31 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10
0 6 7 10 15 10 8 0 15 -4 15 -10z m-9848 -72 c-9 -9 -12 -7 -12 12 0 19 3 21
12 12 9 -9 9 -15 0 -24z m9842 23 c7 -10 -22 -37 -30 -28 -9 8 3 37 15 37 5 0
12 -4 15 -9z m-9604 -31 c0 -5 -9 -10 -21 -10 -11 0 -17 5 -14 10 3 6 13 10
21 10 8 0 14 -4 14 -10z m15 -84 c5 -22 1 -44 -9 -64 -10 -20 -12 -33 -6 -37
11 -7 8 -70 -3 -81 -3 -4 -2 -14 3 -24 6 -11 5 -31 -1 -55 l-11 -37 -15 22
c-11 16 -16 41 -14 81 2 62 -4 69 -39 47 -15 -10 -22 -10 -32 0 -9 9 -8 15 7
26 20 14 19 15 -3 61 -15 31 -19 51 -13 62 7 14 10 13 21 -6 11 -21 13 -21 36
-6 23 15 24 14 24 -5 0 -23 4 -24 30 -10 16 9 18 15 10 36 -12 32 -12 37 0 29
5 -3 12 -21 15 -39z m9603 18 c28 -8 28 -18 -1 -31 -31 -14 -64 2 -46 23 13
16 15 16 47 8z m-9840 -23 c10 -6 10 -10 -3 -21 -18 -15 -42 0 -31 19 8 13 16
14 34 2z m9840 -47 c23 -7 30 -38 11 -50 -6 -3 -9 -19 -5 -35 5 -25 2 -29 -15
-29 -15 0 -19 -4 -15 -16 4 -10 -3 -25 -18 -39 -24 -22 -25 -22 -28 -4 -5 29
-20 28 -38 -4 -14 -26 -14 -29 7 -45 12 -9 22 -24 23 -32 5 -36 0 -42 -30 -38
-24 4 -30 1 -30 -13 0 -10 5 -21 10 -24 17 -10 11 -25 -9 -25 -11 0 -23 -9
-26 -20 -3 -11 -11 -20 -16 -20 -18 0 -21 29 -6 58 9 19 11 35 5 46 -5 10 -5
30 1 53 6 21 11 61 11 88 0 28 6 68 14 89 12 35 19 41 57 52 54 14 68 15 97 8z
m92 -14 c-8 -6 -22 -10 -30 -9 -9 0 -5 6 10 14 27 14 44 10 20 -5z m0 -61 c0
-5 -7 -9 -15 -9 -9 0 -15 9 -15 21 0 18 2 19 15 9 8 -7 15 -16 15 -21z m-9932
-13 c3 -12 -1 -17 -10 -14 -7 3 -15 13 -16 22 -3 12 1 17 10 14 7 -3 15 -13
16 -22z m157 -53 c-8 -34 -11 -37 -24 -28 -6 3 -11 17 -11 31 0 18 5 24 21 24
18 0 20 -4 14 -27z m10 -73 c0 -8 -8 -16 -17 -18 -13 -2 -18 3 -18 18 0 15 5
20 18 18 9 -2 17 -10 17 -18z m-139 -83 c-2 -7 -5 -4 -5 6 -1 9 -6 17 -12 17
-5 0 -7 5 -3 11 9 15 28 -16 20 -34z m134 -17 c0 -22 -4 -40 -10 -40 -5 0 -10
9 -10 19 0 19 -16 36 -34 36 -5 0 -11 6 -13 13 -3 8 7 12 31 12 35 0 36 -1 36
-40z m-140 -25 c0 -8 -9 -15 -20 -15 -20 0 -26 11 -13 23 12 13 33 7 33 -8z
m423 -61 c9 -10 -4 -34 -19 -34 -16 0 -16 2 -8 24 6 17 16 20 27 10z m9234
-49 c14 -14 17 -14 35 2 16 15 21 15 28 3 12 -20 -6 -39 -31 -33 -11 3 -28 -2
-38 -11 -17 -16 -19 -16 -25 0 -6 17 1 54 10 54 3 0 12 -7 21 -15z m-9696 -42
c-8 -15 -10 -15 -11 -2 0 17 10 32 18 25 2 -3 -1 -13 -7 -23z m9726 -75 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m3 -50 c0 -36 -14 -50 -42
-43 -30 8 -58 -19 -40 -37 8 -8 15 -8 27 2 24 20 32 1 33 -74 1 -59 -1 -65
-33 -96 -19 -19 -35 -39 -35 -46 0 -6 -7 -14 -15 -18 -33 -12 -12 -23 45 -20
33 1 60 0 60 -1 0 -18 -15 -32 -30 -28 -26 7 -65 -16 -51 -30 28 -28 -3 -83
-34 -57 -8 6 -15 6 -23 -2 -18 -18 -21 -7 -12 42 5 25 14 91 21 148 6 60 17
109 25 117 16 15 18 36 6 51 -5 5 -5 29 0 52 9 40 11 43 27 28 10 -9 26 -13
39 -9 27 7 27 12 5 29 -17 12 -16 13 5 14 16 0 22 -6 22 -22z m-9716 -75 c6
-19 4 -23 -14 -23 -18 0 -21 4 -16 23 3 12 6 29 6 37 1 13 2 13 9 0 5 -8 12
-25 15 -37z m546 9 c0 -5 -22 -8 -50 -8 -27 1 -50 4 -50 9 0 4 23 7 50 7 28 0
50 -4 50 -8z m-120 -27 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10
15 6 0 10 -7 10 -15z m118 -44 c4 -37 -9 -58 -29 -46 -40 25 -105 2 -80 -29 6
-7 8 -21 5 -31 -6 -14 -10 -8 -20 25 -16 55 -8 67 51 65 46 -2 63 11 23 17
-21 3 -21 4 -4 21 27 27 50 18 54 -22z m9192 -40 c0 -3 -7 -15 -15 -25 -14
-19 -15 -18 -15 12 0 24 4 30 15 26 8 -4 15 -9 15 -13z m-9200 -117 c0 -49 -4
-84 -10 -84 -5 0 -11 -8 -11 -17 -1 -17 -2 -17 -6 0 -2 9 -9 17 -14 17 -6 0
-7 5 -3 11 4 8 9 7 15 -2 6 -9 9 1 9 29 0 40 -1 42 -21 31 -16 -8 -27 -8 -45
2 -26 13 -34 42 -10 33 11 -4 18 4 24 27 8 31 42 54 65 42 4 -2 7 -42 7 -89z
m-120 36 c0 -22 -4 -40 -10 -40 -5 0 -10 18 -10 40 0 22 5 40 10 40 6 0 10
-18 10 -40z m9310 -170 c0 -11 -8 -24 -17 -27 -10 -4 -23 -14 -30 -22 -9 -13
-14 -13 -24 -4 -10 10 -7 19 16 43 33 35 55 39 55 10z m-144 -87 c-10 -10 -19
5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m-9066 -142 c0 -31 -4 -40 -22
-44 -32 -9 -34 -1 -10 33 12 16 22 34 22 40 0 5 2 10 5 10 3 0 5 -17 5 -39z
m196 -67 c-3 -7 -9 -14 -14 -14 -13 0 -33 50 -26 62 9 14 47 -30 40 -48z m74
-99 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z
m-279 -106 c-5 -13 -10 -19 -10 -12 -1 15 10 45 15 40 3 -2 0 -15 -5 -28z
m169 11 c13 -9 13 -11 0 -19 -16 -11 -43 5 -34 20 7 11 15 11 34 -1z m80 -4
c0 -2 -3 -12 -6 -20 -5 -13 -9 -14 -20 -5 -16 13 -9 29 12 29 8 0 14 -2 14 -4z
m-81 -65 c10 -7 11 -15 3 -37 -6 -16 -11 -23 -11 -15 -1 8 -8 19 -18 26 -9 7
-13 17 -9 23 9 14 16 15 35 3z m-82 -21 c-1 -12 -5 -16 -10 -11 -5 5 -6 14 -3
20 11 16 15 13 13 -9z m553 -155 c0 -8 -7 -15 -15 -15 -9 0 -12 6 -9 15 4 8
10 15 15 15 5 0 9 -7 9 -15z m7927 -67 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-7911 -25 c-7 -7 -26 7 -26 19 0 6 6 6 15 -2 9 -7 13 -15 11
-17z m7969 7 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
-10z m183 2 c15 -10 1 -60 -14 -52 -5 3 -10 -5 -12 -17 -6 -41 -28 -26 -27 18
1 48 23 69 53 51z m-7940 -74 c18 -18 15 -27 -8 -25 -12 1 -20 8 -20 19 0 21
11 23 28 6z m7412 -98 c18 -12 2 -12 -25 0 -13 6 -15 9 -5 9 8 0 22 -4 30 -9z
m67 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m158 -4 c3 -5 2
-10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m195 -27 c0
-20 -3 -24 -11 -16 -7 7 -9 19 -6 27 10 24 17 19 17 -11z m50 -3 c0 -4 -7 -10
-15 -14 -10 -4 -15 1 -15 14 0 13 5 18 15 14 8 -4 15 -10 15 -14z m-459 -23
c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z m114 3 c0 -18 -16
-22 -35 -10 -13 9 -13 11 0 20 19 12 35 8 35 -10z m-185 -15 c0 -8 -7 -15 -15
-15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m-7210 -105 c0 -5 -7
-10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-160 -70
c-8 -5 -22 -10 -30 -10 -13 0 -13 1 0 10 8 5 22 10 30 10 13 0 13 -1 0 -10z
m70 -10 c0 -5 -6 -10 -14 -10 -8 0 -18 5 -21 10 -3 6 3 10 14 10 12 0 21 -4
21 -10z m73 -26 c-16 -22 -29 -31 -31 -24 -3 7 3 17 12 22 9 5 16 16 16 23 0
8 7 15 16 15 11 0 8 -9 -13 -36z m7387 1 c7 -8 16 -13 20 -10 14 8 23 -16 12
-27 -8 -8 -16 -7 -27 2 -8 7 -26 10 -40 8 -19 -4 -25 -1 -25 13 0 29 40 38 60
14z m-7540 -31 c0 -8 5 -14 11 -14 6 0 9 -10 7 -22 -4 -27 -24 -25 -21 2 2 14
-5 20 -24 22 -26 3 -26 4 -9 21 19 19 36 15 36 -9z m50 -5 c0 -6 -4 -7 -10 -4
-5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m7858 3 c2 -7 -3 -12
-12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m-7978 -25 c0 -21 -24 -67 -35
-67 -19 0 -36 -39 -26 -58 13 -24 -5 -55 -29 -47 -10 3 -25 -2 -35 -11 -16
-17 -17 -15 -11 22 3 21 5 43 6 49 0 5 5 4 12 -3 15 -15 39 3 31 24 -3 8 3 25
14 37 11 12 26 32 32 45 13 24 41 30 41 9z m339 -48 c-13 -10 -27 -19 -31 -19
-17 0 -6 43 15 60 21 18 22 18 31 -2 8 -16 5 -24 -15 -39z m7250 6 c18 -24 20
-25 30 -8 9 16 10 16 11 -4 0 -13 -6 -23 -14 -23 -7 0 -24 -9 -38 -19 -20 -16
-28 -18 -48 -8 -28 14 -38 37 -16 37 10 0 16 9 16 25 0 34 32 33 59 0z m-7430
-7 c-1 -7 -4 -24 -9 -38 l-7 -25 -10 25 c-11 25 -1 50 18 50 5 0 9 -6 8 -12z
m-419 -13 c0 -2 -7 -5 -15 -9 -9 -3 -15 0 -15 9 0 9 6 12 15 9 8 -4 15 -7 15
-9z m145 5 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
-10z m7847 -20 c16 -26 35 -26 43 0 4 11 10 18 14 16 6 -4 6 -25 2 -69 -1 -5
-5 -6 -10 -2 -5 3 -12 1 -16 -5 -3 -5 1 -10 9 -10 9 0 16 -9 16 -20 0 -13 -7
-20 -18 -20 -19 0 -42 -18 -42 -32 0 -4 14 -8 31 -8 17 0 29 -3 27 -7 -2 -5
-10 -21 -18 -38 -20 -42 -19 -41 -36 -17 -8 11 -20 19 -27 17 -6 -2 -26 -6
-43 -9 l-31 -5 29 29 c34 35 30 47 -17 56 -19 3 -35 10 -35 16 0 21 31 67 50
73 22 7 27 40 8 48 -7 3 2 6 19 6 23 1 37 -5 45 -19z m-7949 3 c-7 -2 -19 -2
-25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m8070 -29 c3 -4 2 -15 -4 -26 -8 -15
-13 -17 -25 -7 -11 9 -12 15 -3 25 12 15 22 17 32 8z m-7523 -14 c0 -5 -2 -10
-4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m7750 0 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-7694 -40 c5
-5 23 -11 39 -12 37 -3 45 -14 38 -50 -4 -21 -2 -28 11 -28 9 0 16 5 16 10 0
6 9 10 20 10 11 0 20 -6 20 -14 0 -21 -23 -36 -40 -26 -8 5 -18 6 -22 3 -15
-8 -38 18 -38 43 0 16 -6 24 -17 24 -9 0 -24 7 -33 15 -13 12 -23 13 -44 6
-31 -12 -84 2 -53 14 9 4 29 5 42 3 14 -3 25 -1 25 3 0 12 24 11 36 -1z m7459
-10 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m-7448 -56 c19 -5 20 -9 11 -35 -9 -27 -28 -40 -28 -20 0 6 -16 7 -36 4 -33
-6 -36 -4 -32 18 2 18 9 24 26 23 12 0 22 3 22 8 0 9 7 10 37 2z m-527 -29 c0
-8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m605 -45 c3 -5 1 -10
-6 -10 -22 0 6 -21 30 -23 37 -2 24 -27 -14 -25 -30 1 -35 5 -35 25 0 12 -6
23 -12 23 -9 0 -8 4 2 10 19 12 27 12 35 0z m-568 -6 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m7686 -11 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5
17 0 24 -2 18 -5z m37 -55 c0 -18 -2 -20 -9 -8 -6 8 -7 18 -5 22 9 14 14 9 14
-14z m-7150 -22 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m160
0 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m133 -40 c-16 -14 -30 -20 -32 -14 -4 13 26 38 46 38 8 0 2 -11 -14 -24z
m6817 1 c0 -8 -8 -24 -19 -36 -22 -25 -33 -10 -20 27 9 24 39 31 39 9z m-6629
-47 c5 0 9 -24 9 -54 0 -38 -5 -57 -17 -67 -15 -12 -16 -12 -10 4 5 12 0 21
-16 28 -29 13 -32 45 -6 52 14 4 19 15 20 39 1 18 3 25 6 16 2 -10 9 -18 14
-18z m-1091 -7 c0 -14 2 -15 9 -4 5 7 14 10 20 6 23 -14 -7 -31 -48 -27 -54 5
-58 42 -4 42 15 0 23 -6 23 -17z m1300 -34 c0 -30 -6 -34 -28 -20 -8 5 -11 17
-7 34 9 34 35 24 35 -14z m-310 17 c0 -17 -27 -29 -35 -16 -4 6 -3 15 0 20 9
15 35 12 35 -4z m550 -9 c0 -12 -7 -32 -15 -44 -11 -15 -12 -22 -3 -25 6 -2 8
-10 4 -16 -5 -8 -13 -9 -25 -3 -23 13 -27 74 -6 95 24 23 45 20 45 -7z m75 14
c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-389 -44 c9 3 14 0 12
-8 -2 -7 9 -15 26 -18 35 -7 36 -19 4 -35 -13 -6 -25 -22 -26 -36 -5 -30 -33
-54 -45 -37 -5 9 -11 7 -21 -5 -14 -19 -42 -25 -31 -8 3 6 1 10 -4 10 -6 0
-11 -6 -11 -12 0 -9 -3 -10 -8 -2 -13 19 -59 26 -66 9 -7 -19 -23 -19 -23 0 0
8 7 16 16 17 24 2 61 4 109 6 29 1 42 6 42 16 0 8 5 16 11 18 10 3 5 21 -19
58 -2 2 -7 -2 -12 -10 -8 -12 -10 -10 -10 8 0 14 6 22 18 23 16 1 16 2 0 6
-21 5 -22 9 -5 27 10 12 13 10 20 -9 5 -15 14 -22 23 -18z m-246 -38 c0 -5 -7
-9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m635 -8 c3 -5 1 -12 -5 -16
-5 -3 -10 1 -10 9 0 18 6 21 15 7z m5432 -13 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m43 13 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16
10 16 6 0 10 -4 10 -9z m362 -53 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9
-15 0 -24z m-6529 -21 c19 -3 20 -5 5 -11 -11 -5 -23 -2 -33 8 -9 9 -20 16
-25 16 -5 0 -7 7 -3 15 5 14 8 13 19 -4 8 -11 24 -21 37 -24z m-177 0 c11 -11
-19 -8 -36 3 -12 8 -10 9 7 7 12 -2 26 -6 29 -10z m6349 -21 c-10 -7 -27 -11
-37 -8 -31 8 -20 22 19 22 34 0 35 -1 18 -14z m-5757 -18 c-17 -26 -49 -30
-56 -9 -3 9 0 11 7 7 7 -4 19 -5 29 -1 14 5 14 7 2 15 -12 8 -10 10 9 10 22 0
23 -1 9 -22z m-218 -18 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10
9 0 16 -4 16 -10z m-362 -22 c-3 -7 -11 -13 -18 -13 -7 0 -15 6 -17 13 -3 7 4
12 17 12 13 0 20 -5 18 -12z m613 -41 c-8 -8 -11 -7 -11 4 0 20 13 34 18 19 3
-7 -1 -17 -7 -23z m-61 -14 c0 -20 -27 -28 -45 -13 -14 11 -13 15 2 30 19 19
43 10 43 -17z m-190 6 c0 -4 -10 -13 -21 -20 -18 -9 -25 -8 -40 7 -18 18 -17
19 21 21 22 0 40 -3 40 -8z m105 1 c3 -5 -6 -17 -20 -26 -26 -17 -35 -66 -15
-79 18 -11 -1 -27 -25 -21 -14 4 -28 0 -35 -9 -18 -21 -29 -19 -40 9 -6 18
-19 26 -50 31 -41 7 -52 30 -14 30 10 1 25 4 32 9 22 13 62 -2 62 -24 0 -11 7
-20 15 -20 8 0 15 5 15 10 0 6 -5 10 -11 10 -5 0 -3 10 5 22 9 12 16 30 16 39
0 25 52 40 65 19z m6138 -88 c-4 -8 -16 -10 -39 -6 -22 4 -40 1 -55 -10 -22
-15 -59 -7 -59 14 0 12 22 15 96 15 46 0 60 -3 57 -13z m-6057 -23 c3 -6 2
-15 -3 -20 -13 -13 -43 -1 -43 17 0 16 36 19 46 3z m174 -87 c0 -10 -12 -29
-26 -41 -27 -24 -45 -22 -25 3 7 8 10 24 6 36 -5 17 -2 20 19 20 18 0 26 -5
26 -18z m5906 10 c18 -12 24 -52 8 -52 -16 0 -54 35 -54 50 0 12 30 13 46 2z
m-128 -35 c2 -12 -3 -17 -17 -17 -12 0 -21 6 -21 13 0 31 32 34 38 4z m-5838
-107 c0 -5 -6 -10 -14 -10 -8 0 -18 5 -21 10 -3 6 3 10 14 10 12 0 21 -4 21
-10z m5460 -37 c0 -2 -7 -11 -15 -20 -13 -12 -18 -13 -30 -3 -20 17 -2 40 25
33 11 -3 20 -7 20 -10z m34 -108 c15 -23 15 -25 -3 -35 -29 -15 -31 -13 -31
25 0 41 11 45 34 10z m110 7 c20 -2 20 -2 0 -17 -24 -18 -52 -19 -69 -1 -22
21 -11 24 69 18z m-760 -8 c11 -4 16 -18 16 -43 0 -41 -13 -67 -29 -57 -7 5
-7 12 1 26 10 15 10 27 1 50 -12 33 -12 33 11 24z m463 0 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m73 -25 c0 -24 -4 -30 -17 -27 -22 4 -30
38 -11 49 22 15 28 10 28 -22z m-140 9 c0 -7 -5 -20 -10 -28 -8 -12 -10 -9
-10 13 0 15 5 27 10 27 6 0 10 -6 10 -12z m-684 -53 c-8 -8 -25 -15 -37 -15
-22 0 -22 0 -3 15 10 8 27 15 37 15 16 0 17 -2 3 -15z m-3496 -60 c0 -9 9 -15
25 -15 14 0 25 5 25 10 0 6 17 10 38 10 43 0 79 -29 69 -55 -8 -19 -26 -19
-33 0 -10 26 -24 16 -18 -12 4 -25 0 -30 -33 -45 -22 -11 -39 -14 -41 -8 -2 6
-10 10 -18 10 -8 0 -14 4 -14 8 0 15 -63 32 -77 21 -10 -9 -13 -6 -13 15 0 16
4 25 10 21 6 -4 15 7 21 24 8 23 17 31 35 31 15 0 24 -6 24 -15z m-320 -4 c0
-13 -29 -35 -37 -28 -7 8 15 37 28 37 5 0 9 -4 9 -9z m130 -6 c0 -8 -6 -15
-14 -15 -17 0 -28 14 -19 24 12 12 33 6 33 -9z m80 -15 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m390 0 c23 -7 23 -8 3 -9
-17 -1 -23 -9 -29 -43 -4 -24 -9 -35 -12 -25 -5 22 -72 25 -72 2 0 -8 -4 -15
-10 -15 -25 0 -6 39 33 68 45 33 49 34 87 22z m262 -27 c23 -21 23 -33 0 -33
-21 0 -42 18 -42 37 0 18 20 16 42 -4z m203 0 c61 -3 114 -8 119 -13 12 -12
-3 -32 -22 -27 -18 4 -73 -20 -84 -36 -7 -11 -38 15 -38 32 0 15 -11 13 -33
-3 -18 -14 -18 -14 0 -19 16 -4 16 -5 -2 -6 -11 0 -27 4 -35 9 -13 9 -13 12 3
21 24 14 11 30 -19 23 -28 -7 -74 2 -74 15 0 13 31 20 55 14 11 -3 70 -8 130
-10z m-1445 -13 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m519 -21 c-8 -5 -28 -9 -45 -9 -50 0 -86 -21 -79 -47 3 -12 1
-25 -5 -28 -6 -4 -10 13 -10 44 0 27 4 51 10 53 23 7 143 -4 129 -13z m1179 3
c3 -9 -2 -13 -14 -10 -9 1 -19 9 -22 16 -3 9 2 13 14 10 9 -1 19 -9 22 -16z
m-70 1 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m112 -33 c0
-19 -20 -27 -33 -13 -8 7 11 33 24 33 5 0 9 -9 9 -20z m-1505 0 c-3 -5 -11
-10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m1658 3 c-7 -2
-19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m70 -8 c1 -5 -6 -11 -15 -13
-11 -2 -18 3 -18 13 0 17 30 18 33 0z m-123 -4 c0 -6 -4 -13 -10 -16 -5 -3
-10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m442 -10 c16 -19 15 -21 -10 -37
-15 -9 -35 -26 -45 -38 l-18 -21 6 25 c3 14 1 32 -4 40 -8 12 -12 12 -29 -3
-17 -16 -21 -16 -50 -1 -18 8 -32 18 -32 21 0 19 42 32 100 33 53 0 69 -4 82
-19z m53 8 c4 -6 23 -10 44 -9 74 4 121 0 121 -10 0 -5 -11 -10 -25 -10 -14 0
-25 -6 -25 -13 0 -8 14 -11 45 -9 36 3 45 8 45 23 0 17 6 18 48 13 26 -3 57
-6 70 -5 12 0 25 -6 29 -15 5 -13 7 -13 15 -2 7 10 11 9 23 -7 14 -17 13 -20
-1 -28 -8 -5 -18 -6 -21 -3 -4 3 -12 1 -20 -6 -17 -14 -63 8 -64 31 0 14 -1
14 -6 1 -12 -29 -37 -40 -78 -34 -24 3 -58 0 -84 -9 -41 -15 -44 -14 -48 4 -3
11 -14 19 -26 19 -16 0 -18 -3 -9 -12 18 -18 15 -28 -9 -28 -16 0 -20 5 -16
20 3 12 -1 24 -11 29 -14 8 -14 11 -2 26 12 14 10 17 -8 22 -12 3 -22 9 -22
14 0 13 27 11 35 -2z m-1798 -35 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8
-13 4 -17z m1483 11 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6
0 10 -7 10 -15z m770 -37 c-11 -10 -25 -16 -31 -13 -15 10 20 45 37 38 12 -4
11 -9 -6 -25z m124 1 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5
-3 7 -10 3 -15z m426 -17 c0 -11 7 -18 18 -18 26 1 30 -19 5 -22 -13 -2 -23
-8 -23 -14 0 -21 -12 1 -20 36 -6 28 -5 36 6 36 8 0 14 -8 14 -18z m-624 -159
c0 -1 -14 1 -31 4 -25 5 -26 7 -10 13 15 5 49 -8 41 -17z"/>
<path d="M9437 9540 c-4 -16 -1 -20 11 -18 19 4 23 29 6 35 -6 2 -14 -6 -17
-17z"/>
<path d="M9473 9206 c-16 -19 -4 -29 13 -11 8 7 14 16 14 19 0 10 -15 6 -27
-8z"/>
<path d="M9520 9170 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M525 9090 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M588 9015 c2 -14 9 -25 15 -25 6 0 7 3 4 6 -3 4 -2 15 4 25 8 16 6
19 -9 19 -15 0 -18 -5 -14 -25z"/>
<path d="M9640 9000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7068 8985 c-5 -5 -8 -611 -8 -1347 l0 -1338 -1945 0 -1945 0 0 1340
0 1340 -112 0 c-116 0 -161 -10 -257 -60 -72 -36 -179 -164 -193 -230 -3 -14
-13 -52 -22 -85 -14 -53 -16 -194 -16 -1390 0 -731 3 -1339 6 -1350 5 -20 13
-20 1272 -20 l1267 0 0 -435 0 -435 -418 -2 c-229 -1 -421 -4 -426 -7 -4 -3
-11 -52 -14 -108 -3 -57 -11 -120 -17 -141 -18 -59 -88 -138 -155 -172 l-59
-30 -423 -3 -423 -3 0 -139 0 -140 1940 0 1940 0 0 139 0 140 -417 3 c-408 3
-419 4 -466 25 -72 34 -120 75 -153 135 -28 50 -29 59 -29 173 l0 120 655 5
c721 6 697 4 800 70 85 54 145 132 186 242 26 69 35 631 31 2063 -2 1169 -4
1284 -19 1321 -64 156 -149 243 -290 295 -56 20 -276 38 -290 24z"/>
<path d="M9665 8611 c-12 -11 -3 -21 20 -21 15 0 25 6 25 14 0 13 -34 19 -45
7z"/>
<path d="M9700 8550 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M9762 8370 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M565 4629 c-10 -15 1 -23 20 -15 9 3 13 10 10 16 -8 13 -22 13 -30
-1z"/>
<path d="M613 4145 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M560 3890 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M592 3833 c-11 -20 0 -43 20 -43 11 0 10 31 -1 49 -7 11 -11 10 -19
-6z"/>
<path d="M9395 3730 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M9271 3656 c-12 -14 -5 -26 16 -26 15 0 18 26 4 34 -5 3 -14 0 -20
-8z"/>
<path d="M1423 3649 c60 -14 145 -58 167 -86 14 -18 20 -21 20 -10 0 35 -129
107 -190 106 l-35 -1 38 -9z"/>
<path d="M1840 3651 l-25 -7 -3 -309 c-2 -275 0 -315 15 -359 25 -68 67 -110
148 -148 62 -28 80 -32 160 -32 95 -1 159 14 210 49 56 38 57 42 15 49 -24 4
-45 15 -54 28 l-16 22 -41 -23 c-37 -21 -51 -23 -124 -19 -103 7 -137 25 -170
92 l-24 49 5 256 6 256 -51 34 c-67 44 -67 57 -2 51 14 -1 21 2 17 9 -8 12
-28 13 -66 2z"/>
<path d="M1995 3641 l80 -6 0 -32 c0 -17 3 -35 8 -39 4 -4 7 14 7 39 l0 47
-87 -2 -88 -2 80 -5z"/>
<path d="M2226 3641 c-4 -5 -9 -139 -12 -298 -3 -158 -7 -294 -9 -300 -3 -8 5
-13 22 -13 14 0 41 -11 59 -25 l33 -25 10 37 c5 21 11 148 13 282 l2 244 -53
41 c-65 49 -59 53 89 48 l100 -3 3 -172 3 -172 -1 180 0 180 -127 3 c-73 1
-129 -1 -132 -7z"/>
<path d="M2926 3631 c-5 -8 32 -11 138 -11 106 0 146 -3 149 -12 3 -8 6 -6 6
5 1 14 -8 17 -61 17 -35 0 -99 3 -144 6 -56 5 -83 3 -88 -5z"/>
<path d="M2765 3618 c-55 -15 -114 -63 -136 -112 -24 -54 -24 -130 2 -186 18
-41 110 -140 129 -140 12 0 123 -75 139 -94 8 -9 11 -26 7 -41 -6 -24 -7 -24
-144 -27 l-137 -3 -3 -107 -3 -108 210 0 c247 0 313 12 385 70 48 39 78 167
53 224 -11 26 -12 22 -9 -45 4 -90 -17 -142 -60 -147 -25 -3 -101 28 -112 46
-3 5 -29 -2 -58 -17 -48 -24 -63 -26 -179 -26 l-127 0 -41 44 c-22 24 -38 47
-35 52 2 5 55 8 117 7 74 -1 125 3 150 12 35 13 44 13 104 -8 69 -25 82 -21
83 21 0 25 -28 78 -55 102 -67 61 -97 85 -164 130 -102 69 -123 96 -120 153 2
33 -1 47 -11 49 -49 13 -90 38 -90 54 0 37 108 92 200 102 29 3 28 4 -10 4
-25 1 -63 -4 -85 -9z"/>
<path d="M3640 3611 c-47 -7 -96 -29 -86 -38 3 -3 11 -1 18 5 29 24 143 34
301 28 87 -4 165 -10 173 -13 10 -3 12 0 8 10 -4 12 -35 15 -187 15 -100 0
-202 -3 -227 -7z"/>
<path d="M4206 3598 c-3 -7 -7 -194 -9 -415 l-5 -403 112 -2 c137 -2 148 -1
158 15 4 6 7 66 6 132 l-2 120 -3 -120 c-2 -66 -8 -124 -12 -128 -12 -12 -121
62 -122 82 0 9 0 153 1 321 l1 306 -55 39 c-31 22 -56 44 -56 49 0 5 116 7
265 4 146 -3 265 -1 265 3 0 4 -122 8 -270 9 -211 0 -271 -2 -274 -12z"/>
<path d="M4760 3601 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M3212 3518 c-1 -50 -6 -78 -13 -78 -6 0 -27 18 -46 40 l-35 40 -125
0 c-94 0 -133 -4 -159 -16 -42 -20 -43 -30 -2 -39 50 -11 98 -47 105 -77 6
-29 33 -61 33 -39 0 6 -4 11 -10 11 -18 0 -11 39 9 49 11 6 61 9 122 6 57 -3
110 -2 117 3 9 6 12 32 9 93 l-3 84 -2 -77z"/>
<path d="M5213 3583 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M5403 3583 c26 -2 67 -2 90 0 23 2 2 3 -48 3 -49 0 -68 -1 -42 -3z"/>
<path d="M5578 3583 c-2 -5 -1 -57 3 -118 l5 -110 2 118 c2 105 -1 132 -10
110z"/>
<path d="M5158 3573 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5737 3169 c-4 -226 -6 -413 -5 -414 2 -2 109 -5 239 -9 252 -6 304
0 359 44 l25 20 -32 -6 c-37 -7 -83 16 -83 42 0 12 -5 13 -22 7 -13 -4 -99 -8
-193 -8 l-170 0 4 313 3 313 -28 30 c-15 16 -32 29 -37 29 -10 0 -37 26 -37
35 0 4 89 5 198 2 143 -3 211 -9 247 -21 55 -18 51 -6 -5 14 -22 8 -110 15
-246 18 l-211 4 -6 -413z"/>
<path d="M3515 3560 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M4047 3563 c-4 -3 -7 -124 -6 -267 0 -144 2 -256 4 -251 3 9 13 525
10 525 -1 0 -5 -3 -8 -7z"/>
<path d="M4770 3490 c0 -45 -4 -80 -10 -80 -5 0 -26 18 -47 40 l-38 40 -142 2
c-128 1 -183 -6 -145 -20 8 -2 29 -18 48 -34 31 -27 33 -34 36 -96 l3 -67 125
-5 125 -5 6 -30 c6 -29 7 -29 8 7 l1 37 -127 3 -128 3 -3 53 -3 52 150 0 151
0 0 90 c0 50 -2 90 -5 90 -3 0 -5 -36 -5 -80z"/>
<path d="M5110 3560 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M7085 3559 c-129 -7 -181 -29 -229 -96 -40 -58 -48 -154 -17 -215 24
-46 98 -121 145 -145 56 -29 125 -84 132 -105 5 -16 0 -27 -16 -40 -22 -18
-22 -18 -1 -18 12 0 21 5 21 10 0 18 64 10 118 -15 37 -17 54 -21 56 -12 2 6
7 20 10 30 16 48 -53 127 -201 231 -110 76 -135 107 -132 160 2 23 0 42 -4 44
-4 2 -23 10 -43 18 -20 9 -43 22 -52 30 -12 13 -10 19 21 47 60 57 122 69 349
65 108 -2 194 0 190 4 -10 10 -217 14 -347 7z"/>
<path d="M7613 3552 c-9 -6 -13 -86 -14 -322 0 -292 1 -318 19 -360 36 -84
105 -136 214 -163 82 -21 131 -21 200 -3 67 18 114 41 157 79 29 25 31 28 10
21 -41 -14 -81 -8 -101 16 l-18 22 -43 -21 c-48 -23 -136 -28 -198 -11 -42 12
-91 61 -107 109 -9 25 -10 101 -5 278 7 274 11 258 -72 311 -30 20 -36 27 -22
30 9 2 15 8 12 13 -7 11 -16 11 -32 1z"/>
<path d="M7664 3549 c-3 -5 39 -10 93 -11 l98 -3 0 -248 c0 -136 3 -247 8
-247 4 0 7 105 8 233 0 127 2 242 5 255 4 21 1 22 -79 23 -45 0 -92 2 -105 3
-13 2 -25 -1 -28 -5z"/>
<path d="M2077 3543 c-11 -10 -8 -43 3 -43 6 0 10 11 10 25 0 26 -2 29 -13 18z"/>
<path d="M8010 3548 c0 -2 -3 -129 -6 -283 -2 -154 -7 -292 -9 -307 -4 -25 -1
-28 24 -28 16 0 40 -10 55 -21 15 -12 28 -20 30 -18 20 24 25 74 27 298 l2
253 -26 21 c-15 12 -39 31 -54 42 l-27 19 24 7 c25 6 185 2 207 -6 10 -3 12
-50 8 -202 -8 -312 -14 -419 -25 -448 -14 -37 -12 -45 4 -15 22 40 25 87 31
391 l6 286 -32 7 c-30 6 -239 9 -239 4z"/>
<path d="M8433 3542 c-23 -3 -23 -5 -24 -185 -1 -100 -4 -274 -8 -387 -3 -113
-4 -219 -3 -236 l4 -31 156 -3 c301 -5 357 -4 382 9 50 24 70 38 95 67 46 53
60 94 60 174 0 79 -18 124 -68 171 l-21 19 22 62 c36 105 37 129 8 180 -62
111 -142 149 -321 155 -60 2 -144 4 -185 6 -41 1 -85 1 -97 -1z m302 -22 c127
-11 182 -28 228 -69 74 -67 85 -195 25 -283 -23 -34 -23 -35 12 -53 38 -20 71
-90 77 -167 6 -63 -5 -103 -44 -155 -31 -43 -64 -43 -105 -2 -27 27 -34 29
-55 20 -14 -7 -90 -11 -185 -11 l-161 0 7 312 c4 171 9 315 12 320 3 4 -2 8
-10 8 -9 0 -16 4 -16 10 0 5 -18 21 -40 35 -22 14 -40 30 -40 35 0 13 140 13
295 0z"/>
<path d="M8609 3397 l50 -42 0 -77 c1 -75 2 -78 27 -84 14 -4 43 -4 64 -1 43
6 67 -12 62 -45 -5 -26 21 -23 57 8 91 76 47 247 -69 269 -19 4 -81 9 -138 11
l-102 4 49 -43z m169 -83 c26 -18 31 -74 8 -93 -19 -16 -66 -21 -93 -10 -19 7
-23 16 -23 48 0 22 3 46 6 55 8 21 72 21 102 0z"/>
<path d="M8585 3094 c11 -9 29 -22 40 -30 16 -12 21 -29 25 -97 l5 -82 68 0
c37 0 71 5 75 11 16 24 41 22 82 -8 48 -35 55 -31 66 39 9 59 -2 96 -39 130
-46 44 -85 53 -221 53 -111 -1 -119 -2 -101 -16z m185 -79 c24 -13 30 -22 30
-49 0 -60 -6 -66 -71 -66 l-59 0 0 58 c0 32 3 62 7 65 11 12 63 7 93 -8z"/>
<path d="M9345 3540 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1622 3535 c2 -5 14 -32 27 -60 22 -48 23 -58 18 -205 -4 -99 -3
-149 3 -140 5 8 10 78 10 155 0 142 -14 215 -49 248 -8 6 -12 8 -9 2z"/>
<path d="M3460 3508 c-11 -12 -30 -42 -43 -67 -19 -41 -23 -67 -29 -236 -3
-104 -7 -241 -7 -302 l-1 -113 135 0 135 0 0 115 0 116 63 -3 62 -3 -3 -97
c-6 -142 -15 -133 138 -136 l130 -3 -2 83 -2 83 -3 -72 c-3 -45 -8 -73 -15
-73 -6 0 -35 18 -65 40 l-53 41 6 114 7 115 -134 1 c-74 0 -159 2 -189 5 -51
3 -53 2 -35 -12 11 -8 35 -26 53 -40 l32 -26 0 -107 c0 -58 -4 -112 -8 -119
-7 -9 -17 -6 -45 15 -20 15 -48 36 -62 46 l-25 18 5 212 c7 255 10 283 35 325
l20 32 -35 14 c-31 13 -40 25 -36 49 2 13 -8 8 -29 -15z"/>
<path d="M6272 3514 c10 -10 20 -15 24 -12 3 4 -5 12 -18 18 -22 12 -22 12 -6
-6z"/>
<path d="M3640 3503 c-39 -14 -42 -17 -26 -27 57 -34 61 -41 47 -82 -16 -51
-11 -186 7 -192 11 -3 12 0 3 15 -14 28 -12 142 4 174 14 27 51 41 85 32 22
-6 27 -36 20 -128 l-5 -70 -42 -3 c-67 -5 -50 -22 21 -22 45 0 67 -4 77 -16
12 -14 61 -44 74 -44 3 0 5 83 5 184 l0 183 -46 7 c-78 10 -176 5 -224 -11z"/>
<path d="M4988 3469 c-52 -69 -59 -109 -65 -421 l-6 -289 134 3 134 3 3 98 c2
53 0 97 -5 97 -4 0 -9 -39 -11 -87 -2 -49 -5 -90 -8 -92 -6 -6 -48 18 -88 51
l-36 29 0 167 c0 196 13 330 34 374 20 39 20 38 -2 38 -20 0 -46 25 -54 49 -3
10 -12 4 -30 -20z"/>
<path d="M5215 3481 c-70 -12 -83 -30 -35 -48 19 -7 30 -18 30 -31 0 -17 3
-18 38 -9 68 18 73 11 70 -100 l-3 -98 -30 -6 c-29 -6 -29 -7 8 -8 34 -1 76
-22 115 -58 26 -25 32 -12 33 65 0 42 2 123 4 180 1 56 0 104 -4 106 -13 9
-188 14 -226 7z"/>
<path d="M6316 3477 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M5900 3470 c0 -3 12 -14 27 -25 60 -44 63 -51 63 -129 0 -77 6 -91
30 -75 13 7 12 9 -2 9 -15 0 -18 10 -18 59 0 50 3 61 20 66 33 10 100 -13 112
-41 14 -30 -2 -71 -34 -83 -20 -7 -19 -8 10 -14 23 -5 33 -13 38 -32 7 -28 13
-30 36 -12 38 31 58 75 58 131 0 50 -3 59 -38 95 -29 31 -47 40 -82 45 -62 8
-220 12 -220 6z"/>
<path d="M2068 3319 c-2 -83 0 -153 5 -156 4 -2 7 66 7 151 0 86 -2 156 -4
156 -3 0 -6 -68 -8 -151z"/>
<path d="M7063 3443 c-13 -2 -23 -9 -23 -14 0 -5 -7 -9 -15 -9 -8 0 -15 -4
-15 -10 0 -5 6 -10 13 -10 6 0 35 -11 63 -24 39 -18 53 -31 58 -52 9 -34 24
-54 40 -54 6 0 3 5 -6 11 -23 13 -23 46 0 58 10 6 66 9 124 7 59 -2 112 1 120
5 10 7 10 9 -3 9 -8 0 -34 20 -57 45 l-42 45 -117 -1 c-65 -1 -128 -4 -140 -6z"/>
<path d="M6355 3347 c0 -57 -5 -80 -22 -110 -13 -21 -23 -42 -23 -47 0 -5 16
-25 36 -44 46 -44 67 -111 60 -191 l-5 -60 11 55 c15 81 -3 154 -54 204 -33
34 -37 41 -25 55 25 30 37 72 37 129 0 31 -4 62 -8 69 -4 6 -7 -21 -7 -60z"/>
<path d="M5192 3273 c-2 -83 0 -93 16 -93 11 0 13 3 5 8 -7 5 -13 41 -16 92
l-3 85 -2 -92z"/>
<path d="M5581 3334 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M805 3310 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"/>
<path d="M3045 3290 c17 -11 35 -20 40 -20 6 0 -5 9 -23 20 -42 26 -56 26 -17
0z"/>
<path d="M9280 3281 c-24 -7 -42 -41 -21 -41 6 0 11 6 11 13 0 9 2 9 8 -1 6
-9 14 -6 32 13 24 26 18 29 -30 16z"/>
<path d="M2470 3238 c0 -24 5 -50 10 -58 7 -11 10 2 10 43 0 31 -4 57 -10 57
-5 0 -10 -19 -10 -42z"/>
<path d="M3100 3256 c0 -7 92 -67 96 -63 5 4 -77 67 -88 67 -4 0 -8 -2 -8 -4z"/>
<path d="M5583 3205 c0 -33 2 -45 4 -27 2 18 2 45 0 60 -2 15 -4 0 -4 -33z"/>
<path d="M7223 3240 c14 -11 29 -20 34 -20 10 1 -39 39 -51 40 -5 0 3 -9 17
-20z"/>
<path d="M6045 3240 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M772 3208 c-20 -20 -14 -27 12 -15 13 7 22 15 20 19 -7 11 -18 10
-32 -4z"/>
<path d="M7270 3213 c0 -11 81 -56 88 -49 3 3 0 6 -6 6 -6 0 -17 6 -24 13 -21
19 -58 38 -58 30z"/>
<path d="M4730 3161 c0 -22 -5 -43 -10 -46 -6 -3 -27 10 -48 29 l-37 36 -127
0 c-71 0 -128 -2 -128 -4 0 -2 18 -16 40 -31 34 -24 40 -33 40 -64 1 -32 2
-34 12 -16 6 11 12 22 14 24 2 2 59 3 127 2 l124 -2 2 55 c1 31 -1 56 -4 56
-3 0 -5 -18 -5 -39z"/>
<path d="M802 3153 c-16 -29 -15 -33 8 -33 22 0 23 4 11 35 -8 19 -8 19 -19
-2z"/>
<path d="M3220 3156 c0 -2 7 -9 15 -16 13 -11 14 -10 9 4 -5 14 -24 23 -24 12z"/>
<path d="M5905 3151 c-6 -5 22 -31 33 -31 5 0 17 -9 28 -21 14 -15 18 -35 17
-82 l-1 -62 10 58 c12 67 30 78 90 53 54 -23 74 -69 43 -100 -14 -13 -12 -14
18 -9 28 4 40 0 69 -26 l35 -30 12 25 c14 32 14 109 0 137 -34 65 -73 80 -222
87 -71 3 -130 3 -132 1z"/>
<path d="M2472 3130 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7395 3120 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M7430 3094 c0 -13 18 -39 24 -34 2 3 -2 14 -10 24 -8 11 -14 16 -14
10z"/>
<path d="M5096 3082 c-6 -10 20 -33 58 -50 22 -10 27 -18 23 -37 -2 -14 0 -25
4 -25 5 0 9 5 9 10 0 6 27 10 60 10 l60 0 0 -114 0 -114 124 -7 c69 -4 130 -4
135 0 6 3 11 16 11 28 -1 20 -2 20 -11 5 -12 -21 -23 -23 -41 -5 -7 6 -30 25
-50 40 l-38 28 0 115 0 114 -147 1 c-82 0 -158 2 -170 5 -12 2 -24 0 -27 -4z"/>
<path d="M2465 3040 c-4 -17 -5 -34 -2 -36 2 -3 7 10 11 27 8 42 0 50 -9 9z"/>
<path d="M5572 3040 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7465 2953 c0 -60 -4 -88 -12 -91 -7 -2 -13 -10 -13 -18 0 -25 -72
-17 -116 12 -33 23 -41 24 -58 14 -42 -26 -123 -40 -228 -39 l-107 1 -36 39
c-20 22 -35 47 -33 57 2 10 -3 17 -12 17 -12 0 -16 -20 -18 -108 l-3 -109 238
4 c224 3 241 5 290 26 60 28 86 53 109 107 18 42 23 150 8 165 -5 5 -9 -30 -9
-77z"/>
<path d="M4030 3005 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"/>
<path d="M2133 3003 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M7851 2983 c-1 -12 4 -25 9 -28 12 -7 12 2 0 30 -8 19 -9 19 -9 -2z"/>
<path d="M2451 2974 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5574 2895 c0 -49 1 -71 3 -48 2 23 2 64 0 90 -2 26 -3 8 -3 -42z"/>
<path d="M5980 2940 c0 -5 12 -10 28 -9 24 0 25 1 7 9 -27 12 -35 12 -35 0z"/>
<path d="M7000 2940 l-45 -7 47 -1 c26 -1 50 3 53 8 3 6 3 9 -2 8 -4 0 -28 -4
-53 -8z"/>
<path d="M6053 2933 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M7872 2924 c10 -10 20 -16 22 -13 3 3 -5 11 -17 18 -21 13 -21 12 -5
-5z"/>
<path d="M6391 2874 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6367 2843 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M895 2790 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"/>
<path d="M950 2779 c0 -18 2 -19 15 -5 8 8 15 17 15 20 0 3 -7 6 -15 6 -9 0
-15 -9 -15 -21z"/>
<path d="M1003 2765 c-3 -9 -3 -18 -1 -21 9 -9 38 8 38 22 0 19 -29 18 -37 -1z"/>
<path d="M1200 2720 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M955 2715 c-3 -8 -7 -22 -10 -30 -2 -8 0 -17 5 -20 6 -4 10 -1 10 6
0 7 7 20 15 29 14 16 14 18 0 24 -9 3 -18 0 -20 -9z"/>
<path d="M1050 2676 c0 -8 5 -18 10 -21 6 -3 10 3 10 14 0 12 -4 21 -10 21 -5
0 -10 -6 -10 -14z"/>
<path d="M890 2666 c0 -21 21 -31 31 -15 14 22 11 29 -11 29 -11 0 -20 -6 -20
-14z"/>
<path d="M1155 2670 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M9155 2670 c-3 -5 -1 -11 5 -15 6 -4 8 -10 5 -15 -9 -15 26 -60 46
-60 20 0 60 19 70 34 4 5 23 6 43 3 20 -3 36 -2 36 4 0 11 -2 11 -58 13 -30 2
-47 -3 -59 -16 -21 -24 -39 -23 -46 2 -3 11 -2 18 2 16 4 -3 13 -1 21 4 12 7
12 12 3 21 -15 15 -61 21 -68 9z"/>
<path d="M1017 2633 c-16 -42 -14 -55 8 -46 10 3 15 19 15 44 0 21 -2 39 -4
39 -2 0 -11 -17 -19 -37z"/>
<path d="M1216 2661 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M950 2614 c0 -14 4 -23 10 -19 6 3 10 15 10 26 0 10 -4 19 -10 19 -5
0 -10 -12 -10 -26z"/>
<path d="M1276 2612 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
<path d="M1546 2612 c-2 -4 -1 -14 3 -21 11 -17 51 -5 51 15 0 15 -45 20 -54
6z"/>
<path d="M1378 2603 c-10 -2 -18 -9 -18 -14 0 -15 43 -7 48 9 4 12 0 13 -30 5z"/>
<path d="M8555 2600 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M1553 2549 c14 -8 22 -8 30 0 8 8 3 11 -18 11 -26 -1 -28 -2 -12 -11z"/>
<path d="M8839 2519 c-20 -26 -28 -29 -48 -21 -16 6 -20 11 -13 16 17 10 15
26 -3 26 -8 0 -15 -9 -15 -19 0 -11 -6 -21 -12 -24 -21 -7 4 -40 33 -43 13 -2
39 -6 58 -10 l34 -7 -7 37 c-4 23 -3 36 4 36 5 0 10 9 10 20 0 28 -14 25 -41
-11z"/>
<path d="M8679 2536 c-7 -46 4 -91 20 -82 12 8 5 80 -8 84 -6 2 -11 1 -12 -2z"/>
<path d="M1565 2510 c-8 -13 3 -30 20 -30 7 0 15 -5 17 -12 3 -9 6 -9 12 0 4
7 2 12 -3 12 -6 0 -11 6 -11 14 0 17 -27 29 -35 16z"/>
<path d="M1741 2492 c-7 -13 -11 -26 -8 -28 8 -8 29 25 25 38 -3 8 -9 4 -17
-10z"/>
<path d="M1770 2506 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M1666 2448 c1 -29 3 -57 4 -63 0 -6 4 -5 8 2 9 14 3 113 -8 113 -4 0
-6 -24 -4 -52z"/>
<path d="M8610 2470 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M1724 2439 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"/>
<path d="M1786 2441 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M8697 2409 c-3 -13 0 -22 10 -26 21 -8 34 17 18 34 -18 18 -22 17
-28 -8z"/>
<path d="M1880 2400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1654 2336 c-3 -9 -2 -22 3 -29 13 -15 9 -69 -7 -107 -14 -34 -6 -80
14 -80 8 0 17 -6 20 -12 3 -7 3 -1 0 14 -5 20 -2 30 10 34 15 6 15 8 1 24 -22
24 -15 67 12 79 13 6 23 15 23 21 0 6 -12 3 -26 -7 l-26 -17 3 44 c4 44 -15
68 -27 36z"/>
<path d="M1730 2309 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1787 2309 c8 -8 16 -8 30 0 16 9 14 10 -11 11 -22 0 -27 -3 -19 -11z"/>
<path d="M8611 2268 c-5 -13 -7 -25 -5 -27 7 -7 34 21 34 35 0 22 -20 16 -29
-8z"/>
<path d="M1991 2164 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8608 2163 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1760 2149 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2081 2144 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2000 2126 c0 -7 11 -18 24 -24 19 -8 27 -8 36 3 13 16 10 18 -37 28
-14 3 -23 0 -23 -7z"/>
<path d="M1737 2116 c-17 -13 -23 -36 -9 -36 8 0 35 40 30 45 -2 2 -11 -2 -21
-9z"/>
<path d="M1890 2090 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M8723 2088 c-31 -15 -29 -24 8 -37 21 -7 37 -7 50 0 27 14 24 16 -12
11 -28 -4 -30 -2 -24 17 3 11 5 21 3 20 -2 0 -13 -5 -25 -11z"/>
<path d="M1858 2063 c-10 -2 -18 -11 -18 -20 0 -10 5 -13 18 -8 9 4 25 10 35
12 9 3 17 9 17 14 0 9 -22 10 -52 2z"/>
<path d="M1735 2030 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z"/>
<path d="M2010 2036 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M1784 1960 c-1 -38 0 -40 27 -40 22 0 29 -5 29 -20 0 -11 5 -20 11
-20 6 0 8 9 4 20 -4 12 -2 20 6 20 9 0 8 4 -1 15 -7 8 -19 15 -26 15 -8 0 -14
10 -14 25 0 17 -5 25 -18 25 -13 0 -18 -9 -18 -40z"/>
<path d="M1940 1990 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8722 1988 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
<path d="M2220 1961 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M1940 1930 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2010 1831 c0 -18 18 -21 30 -6 10 13 9 15 -9 15 -11 0 -21 -4 -21
-9z"/>
<path d="M2240 1825 c0 -8 -4 -15 -10 -15 -5 0 -10 -4 -10 -10 0 -5 8 -10 19
-10 12 0 20 9 23 25 4 17 1 25 -8 25 -8 0 -14 -7 -14 -15z"/>
<path d="M8504 1825 c-8 -21 4 -35 32 -35 31 0 31 22 -2 37 -20 9 -26 9 -30
-2z"/>
<path d="M2127 1796 c-3 -7 -20 -17 -39 -21 l-33 -9 40 -4 c29 -2 43 1 52 14
12 17 9 34 -7 34 -4 0 -10 -6 -13 -14z"/>
<path d="M8520 1770 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8442 1709 c-13 -5 -22 -14 -20 -21 8 -22 25 -19 42 7 18 27 14 29
-22 14z"/>
<path d="M8498 1699 c-5 -16 14 -139 22 -139 31 0 29 142 -1 148 -10 2 -19 -2
-21 -9z"/>
<path d="M2221 1673 c-23 -15 -23 -17 -6 -29 16 -12 20 -11 33 7 20 29 2 43
-27 22z"/>
<path d="M2785 1677 c-17 -9 -20 -37 -4 -37 14 0 53 21 61 33 4 6 12 4 23 -6
21 -22 25 -21 25 3 0 17 -6 19 -45 18 -25 -1 -52 -6 -60 -11z"/>
<path d="M2320 1669 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8345 1670 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M2507 1643 c-14 -13 -7 -43 13 -53 25 -14 66 25 49 47 -12 14 -51 18
-62 6z"/>
<path d="M4305 1639 c-4 -5 1 -19 10 -29 14 -16 16 -39 14 -162 l-3 -143 68
-3 c48 -2 75 1 91 12 21 14 23 13 28 -3 3 -14 17 -20 48 -23 24 -3 50 -3 57 0
17 6 15 39 -4 50 -21 12 -16 127 6 127 8 0 16 -6 16 -12 1 -7 2 -17 3 -23 2
-16 48 -11 55 6 22 58 -9 84 -102 84 -70 0 -72 -1 -72 -25 0 -14 5 -25 10 -25
14 0 13 -117 -1 -126 -19 -11 -29 18 -25 76 2 30 2 65 -1 76 -5 25 -42 44 -83
44 -30 0 -30 1 -30 55 l0 55 -39 0 c-22 0 -43 -5 -46 -11z m141 -196 c5 -55
-10 -93 -36 -93 -18 0 -20 6 -20 71 0 70 0 70 26 67 23 -3 27 -8 30 -45z"/>
<path d="M2814 1616 c-7 -30 1 -46 24 -46 31 0 45 16 38 44 -9 36 -53 37 -62
2z"/>
<path d="M5060 1616 c0 -13 6 -26 12 -28 20 -7 11 -238 -9 -254 -31 -22 -8
-44 47 -44 47 0 50 2 50 25 0 14 -4 25 -10 25 -6 0 -10 28 -10 65 l0 65 30 0
30 0 0 -90 0 -90 40 0 c45 0 66 25 37 42 -14 7 -17 25 -17 89 0 76 -1 80 -26
89 -14 6 -41 10 -59 10 l-34 0 -3 58 -3 57 -37 3 c-35 3 -38 1 -38 -22z"/>
<path d="M6055 1622 c-11 -11 -4 -43 10 -48 9 -4 15 -19 15 -35 0 -27 -3 -29
-38 -29 -21 0 -48 -7 -60 -16 -20 -14 -22 -24 -22 -98 0 -50 5 -89 13 -99 10
-14 39 -20 142 -28 71 -6 133 -11 138 -12 5 -1 14 6 20 16 10 15 9 21 -5 31
-14 10 -18 32 -20 102 l-3 89 -33 3 c-37 4 -53 -19 -34 -49 7 -10 12 -43 12
-72 0 -59 -14 -84 -36 -62 -11 10 -14 50 -14 159 0 80 -3 146 -7 147 -5 0 -22
2 -40 3 -18 2 -35 1 -38 -2z m3 -168 c21 -6 23 -11 20 -63 -3 -53 -5 -56 -30
-59 l-28 -3 0 65 c0 36 3 66 8 66 4 0 17 -3 30 -6z"/>
<path d="M8668 1599 c-2 -16 -13 -25 -34 -31 -40 -10 -54 -38 -20 -38 14 0 30
-10 40 -25 9 -14 23 -25 32 -25 22 0 12 23 -15 37 l-23 11 26 17 c20 13 26 25
24 43 -4 33 -25 40 -30 11z"/>
<path d="M8735 1610 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"/>
<path d="M3550 1586 c0 -13 -7 -29 -15 -36 -20 -16 -19 -46 1 -54 12 -5 14
-15 8 -51 -13 -89 10 -132 71 -131 29 1 34 46 6 53 -18 5 -21 13 -21 64 0 48
4 60 20 69 26 14 26 40 0 47 -12 3 -20 13 -19 22 3 33 -2 41 -26 41 -20 0 -25
-5 -25 -24z"/>
<path d="M4740 1576 c0 -22 4 -26 31 -26 26 0 30 3 27 23 -2 16 -11 23 -30 25
-24 3 -28 0 -28 -22z"/>
<path d="M6997 1594 c-12 -13 -8 -44 8 -50 12 -5 14 -25 9 -123 -3 -64 -11
-119 -17 -123 -5 -3 -18 -1 -29 5 -15 10 -18 25 -18 88 0 49 -4 80 -12 87 -20
15 -89 23 -123 14 -29 -8 -47 -42 -37 -69 6 -18 51 -16 58 2 4 8 17 15 30 15
19 0 24 -5 24 -25 0 -24 -3 -25 -51 -24 -34 2 -54 -2 -60 -12 -13 -21 -11 -93
3 -107 16 -16 184 -16 194 0 5 8 10 5 15 -9 6 -16 16 -19 56 -19 45 1 48 3 51
29 2 20 -1 27 -13 27 -15 0 -16 16 -13 148 l3 147 -35 3 c-20 2 -39 0 -43 -4z
m-109 -267 c3 -13 -3 -17 -27 -17 -20 0 -31 5 -31 14 0 29 53 32 58 3z"/>
<path d="M7283 1585 c-17 -12 -23 -26 -23 -54 0 -22 -6 -44 -15 -51 -8 -7 -15
-20 -15 -30 0 -10 7 -23 15 -30 10 -8 15 -30 15 -61 0 -38 -4 -51 -20 -59 -22
-12 -26 -35 -9 -45 20 -13 91 -16 104 -5 14 11 9 50 -6 50 -5 0 -9 25 -9 56 0
42 4 60 17 69 29 22 43 -5 43 -84 0 -89 14 -101 113 -101 51 0 69 -4 73 -15 4
-8 11 -15 16 -15 6 0 8 7 5 15 -4 8 -2 17 3 20 12 7 8 39 -8 57 -6 8 -12 50
-14 93 l-3 80 -40 0 c-36 0 -40 -3 -43 -27 -2 -16 1 -28 7 -28 17 0 13 -57 -4
-64 -8 -3 -15 -10 -15 -17 0 -6 7 -9 15 -5 10 4 15 -1 15 -14 0 -16 -7 -20
-30 -20 l-31 0 3 88 3 87 -50 1 c-27 1 -56 2 -62 3 -7 1 -13 14 -13 30 0 21 6
30 21 34 15 4 20 12 17 29 -4 29 -43 36 -75 13z"/>
<path d="M6197 1573 c-4 -3 -7 -17 -7 -30 0 -18 6 -23 24 -23 30 0 40 13 31
40 -6 19 -35 27 -48 13z"/>
<path d="M6551 1566 c-16 -20 -3 -46 24 -46 13 0 26 7 29 16 7 19 -10 44 -29
44 -7 0 -18 -6 -24 -14z"/>
<path d="M5330 1541 c0 -16 -6 -31 -15 -35 -18 -6 -20 -27 -4 -43 7 -7 12 -38
11 -69 0 -78 13 -111 46 -117 15 -3 30 -3 34 0 14 8 9 41 -7 47 -11 4 -15 21
-15 64 0 50 4 62 23 76 l22 16 -22 16 c-15 11 -23 27 -23 45 0 24 -4 29 -25
29 -21 0 -25 -5 -25 -29z"/>
<path d="M8268 1556 c-19 -14 -23 -26 -10 -26 4 0 18 9 32 20 16 13 20 20 10
19 -8 0 -23 -6 -32 -13z"/>
<path d="M6667 1554 c-4 -4 -7 -18 -7 -30 0 -13 -4 -26 -10 -29 -5 -3 -10 -17
-10 -31 0 -13 5 -24 10 -24 6 0 10 -33 10 -80 0 -86 9 -100 64 -100 21 0 26 5
26 25 0 16 -6 25 -15 25 -12 0 -15 14 -15 64 0 55 3 64 20 69 11 3 20 12 20
20 0 16 -29 45 -31 31 -1 -5 -4 7 -8 26 -4 25 -12 36 -26 38 -12 2 -24 0 -28
-4z"/>
<path d="M8396 1547 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M3206 1535 c-15 -16 -20 -49 -18 -143 1 -57 15 -72 68 -72 38 0 44
-3 44 -20 0 -27 -29 -38 -45 -17 -7 10 -22 17 -34 17 -19 0 -22 -5 -19 -32 3
-31 6 -34 51 -42 30 -6 58 -6 75 0 l27 9 3 124 c2 94 6 127 18 138 8 9 14 23
12 32 -5 24 -159 29 -182 6z m99 -100 c0 -60 0 -60 -30 -60 -24 0 -29 4 -28
23 0 12 1 40 2 63 1 38 3 40 29 37 26 -3 27 -5 27 -63z"/>
<path d="M7643 1543 c-7 -3 -13 -16 -13 -29 0 -14 -7 -27 -15 -30 -17 -7 -20
-49 -5 -59 6 -3 10 -39 10 -80 0 -65 3 -76 22 -89 12 -9 30 -16 39 -16 10 0
20 -6 22 -12 8 -19 27 11 27 40 0 16 -7 26 -20 29 -17 5 -20 14 -20 64 0 63 9
76 41 59 19 -11 20 -19 18 -86 -5 -104 -4 -105 162 -108 73 -2 141 -1 151 2
19 5 26 52 8 52 -6 0 -10 28 -10 65 0 50 3 65 14 65 8 0 16 -10 18 -22 5 -36
49 -31 59 6 16 58 -22 81 -126 74 -40 -2 -51 -7 -53 -22 -2 -10 3 -23 11 -27
17 -10 14 -129 -5 -140 -29 -18 -38 3 -38 92 0 49 -1 89 -2 89 -72 11 -78 11
-84 -5 -4 -9 -2 -26 4 -38 17 -35 22 -73 16 -106 -5 -25 -11 -30 -31 -29 -30
3 -38 30 -29 101 10 76 2 89 -48 86 -52 -3 -86 14 -79 40 5 21 -22 42 -44 34z"/>
<path d="M3676 1525 c-15 -11 -19 -30 -21 -97 -3 -115 6 -128 90 -128 80 0 85
7 85 129 0 79 -2 92 -19 101 -28 15 -113 12 -135 -5z m92 -99 c3 -63 -5 -81
-31 -70 -13 5 -17 19 -17 63 0 62 5 73 30 69 11 -2 16 -18 18 -62z"/>
<path d="M3984 1515 c-8 -13 -14 -32 -12 -42 4 -23 46 -30 54 -9 4 10 18 16
35 16 22 0 29 -5 29 -20 0 -17 -7 -20 -42 -20 -48 0 -60 -6 -76 -35 -11 -22
-5 -72 11 -92 15 -20 186 -18 194 2 3 9 -2 24 -11 34 -11 12 -16 37 -16 82 0
92 -14 109 -90 109 -52 0 -62 -3 -76 -25z m102 -136 c3 -6 2 -15 -3 -20 -12
-12 -53 1 -53 17 0 17 46 19 56 3z"/>
<path d="M8230 1515 c0 -8 7 -15 15 -15 8 0 15 4 15 9 0 5 -7 11 -15 15 -9 3
-15 0 -15 -9z"/>
<path d="M4717 1506 c-3 -8 1 -22 8 -33 20 -25 20 -122 0 -129 -8 -4 -15 -17
-15 -30 0 -22 4 -24 55 -24 52 0 55 1 55 25 0 14 -4 25 -10 25 -5 0 -11 39
-12 88 l-3 87 -36 3 c-25 2 -38 -2 -42 -12z"/>
<path d="M4853 1508 c-16 -20 -26 -159 -14 -191 10 -24 15 -27 61 -27 44 0 50
-3 50 -20 0 -27 -29 -38 -45 -17 -17 24 -65 23 -65 -1 0 -37 23 -52 82 -53 32
-1 64 3 71 10 11 8 16 44 19 132 3 84 8 123 17 128 7 5 11 17 9 27 -3 17 -14
19 -89 22 -61 2 -89 -1 -96 -10z m103 -100 c-1 -61 -2 -63 -28 -66 l-28 -3 0
65 c0 66 0 66 28 66 28 0 29 0 28 -62z"/>
<path d="M8380 1505 c0 -17 2 -18 24 -9 23 9 20 24 -4 24 -11 0 -20 -7 -20
-15z"/>
<path d="M5456 1497 c-21 -15 -33 -148 -17 -190 10 -26 14 -27 81 -27 67 0 71
1 80 26 6 14 8 30 4 35 -8 14 -35 11 -54 -6 -21 -19 -60 -9 -60 16 0 15 9 18
58 21 l57 3 3 52 c3 44 0 53 -20 67 -27 19 -107 21 -132 3z m92 -58 c3 -18
-29 -31 -47 -19 -7 3 -9 14 -5 24 8 21 48 17 52 -5z"/>
<path d="M5637 1503 c-11 -10 -8 -43 3 -43 18 0 12 -121 -7 -142 -22 -25 -7
-38 46 -41 49 -2 69 18 45 44 -19 22 -20 139 0 139 8 0 17 -11 20 -25 5 -18
13 -25 31 -25 25 0 29 7 25 45 -4 40 -29 55 -95 55 -34 0 -65 -3 -68 -7z"/>
<path d="M6317 1482 c-21 -23 -25 -190 -5 -210 7 -7 31 -12 55 -12 36 0 43 -3
43 -20 0 -13 -7 -20 -20 -20 -11 0 -20 4 -20 9 0 15 -30 22 -50 11 -16 -8 -17
-14 -7 -39 9 -25 17 -30 57 -35 26 -3 61 -1 78 5 l32 11 0 128 c0 101 3 129
14 133 25 10 7 44 -26 51 -63 13 -134 7 -151 -12z m101 -104 c-3 -60 -4 -63
-30 -66 l-28 -3 0 65 0 66 30 0 31 0 -3 -62z"/>
<path d="M6527 1493 c-12 -12 -7 -40 9 -54 21 -18 13 -115 -11 -133 -10 -7
-14 -18 -9 -29 5 -13 18 -17 60 -17 50 0 54 2 54 24 0 13 -6 26 -14 29 -10 4
-12 24 -10 83 2 46 -1 83 -8 91 -11 14 -60 18 -71 6z"/>
<path d="M8427 1463 c-15 -14 -6 -23 23 -23 20 0 30 5 30 15 0 15 -40 21 -53
8z"/>
<path d="M8510 1450 c-8 -5 -12 -12 -8 -15 10 -10 49 5 43 16 -8 11 -16 11
-35 -1z"/>
<path d="M2892 1428 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
<path d="M3788 1283 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4320 1250 c0 -11 7 -20 15 -20 8 0 15 6 15 14 0 8 -7 16 -15 20 -10
4 -15 -1 -15 -14z"/>
<path d="M2674 1222 c-6 -4 -11 -25 -11 -47 0 -38 1 -40 31 -38 46 2 46 2 46
13 0 6 -13 10 -30 10 -23 0 -30 5 -30 19 0 11 6 21 13 24 8 3 9 8 2 15 -6 6
-15 8 -21 4z"/>
<path d="M2755 1210 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M4353 1191 c2 -18 10 -27 31 -32 25 -7 27 -6 22 14 -3 12 -6 28 -6
34 0 7 -11 12 -24 11 -19 -2 -24 -7 -23 -27z"/>
<path d="M4401 1103 c-48 -4 -52 -5 -35 -18 20 -15 71 -19 98 -9 19 7 22 35 4
32 -7 -1 -37 -3 -67 -5z"/>
<path d="M4240 1050 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4460 1045 c-11 -13 -10 -14 4 -9 9 3 16 10 16 15 0 13 -6 11 -20 -6z"/>
<path d="M4380 1041 c0 -5 7 -14 15 -21 13 -10 15 -9 15 9 0 12 -6 21 -15 21
-8 0 -15 -4 -15 -9z"/>
<path d="M4668 1043 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4295 1030 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8235 1030 c-3 -5 -15 -10 -26 -10 -11 0 -24 -11 -31 -25 -6 -13 -18
-26 -27 -28 -9 -3 -4 -5 11 -6 15 0 30 4 33 9 3 6 14 10 24 10 10 0 21 8 25
18 3 9 9 23 12 30 7 14 -12 17 -21 2z"/>
<path d="M2800 1005 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"/>
<path d="M2960 983 c0 -22 2 -25 10 -13 13 20 13 40 0 40 -5 0 -10 -12 -10
-27z"/>
<path d="M2872 980 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4320 990 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2770 951 c0 -17 22 -24 31 -10 6 11 12 10 28 -6 23 -20 37 -15 28
10 -7 17 -87 22 -87 6z"/>
<path d="M4340 927 c0 -8 -3 -22 -6 -31 -10 -26 72 -22 86 4 8 15 6 21 -8 29
-13 7 -22 7 -30 -1 -8 -8 -15 -8 -27 2 -12 10 -15 10 -15 -3z"/>
<path d="M8120 931 c0 -5 7 -12 16 -15 22 -9 24 -8 24 9 0 8 -9 15 -20 15 -11
0 -20 -4 -20 -9z"/>
<path d="M4145 920 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M8280 896 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z"/>
<path d="M7115 790 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M7680 791 c0 -5 7 -11 15 -15 9 -3 15 0 15 9 0 8 -7 15 -15 15 -8 0
-15 -4 -15 -9z"/>
<path d="M10065 6710 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M9985 5220 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M9991 5174 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9915 5120 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M9911 5054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9900 4690 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M9835 4319 c-8 -12 -6 -15 21 -23 16 -5 23 -2 27 14 4 16 0 20 -18
20 -13 0 -27 -5 -30 -11z"/>
<path d="M3765 570 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M3720 544 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z"/>
<path d="M1950 10226 c0 -21 21 -31 31 -15 14 22 11 29 -11 29 -11 0 -20 -6
-20 -14z"/>
<path d="M2020 10215 c0 -28 21 -33 39 -11 14 17 51 9 51 -10 0 -8 7 -14 15
-14 8 0 15 9 15 20 0 13 7 20 20 20 22 0 27 -16 8 -23 -21 -8 22 -35 45 -29
12 3 25 -2 33 -13 8 -10 20 -15 29 -11 13 5 13 9 3 26 -10 16 -10 22 4 36 22
22 42 10 26 -16 -7 -11 -9 -23 -4 -27 4 -4 12 0 17 10 6 10 34 29 62 42 l52
24 -82 1 c-45 0 -94 -6 -109 -12 -22 -10 -28 -10 -32 0 -2 8 -32 12 -98 12
-94 0 -94 0 -94 -25z"/>
<path d="M2545 10230 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2570 10225 c0 -11 7 -14 23 -10 15 4 28 1 36 -9 18 -21 28 -20 34 4
3 13 11 18 20 14 8 -3 18 -1 22 5 4 8 -17 11 -64 11 -57 0 -71 -3 -71 -15z"/>
<path d="M8030 10231 c0 -5 6 -14 14 -20 11 -9 15 -8 20 5 9 23 8 24 -14 24
-11 0 -20 -4 -20 -9z"/>
<path d="M8080 10225 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
0 -15 -7 -15 -15z"/>
<path d="M8345 10231 c-3 -5 -20 -13 -38 -17 l-32 -7 29 -8 c28 -8 76 12 76
32 0 12 -27 12 -35 0z"/>
<path d="M8412 10228 c-14 -14 -16 -68 -3 -68 5 0 12 9 16 19 10 33 32 45 50
28 17 -17 20 -31 5 -22 -5 3 -10 -1 -10 -9 0 -9 7 -16 15 -16 17 0 21 -46 5
-70 -5 -9 -5 -19 1 -26 7 -8 6 -14 -1 -19 -13 -8 -13 -35 0 -35 6 0 12 6 14
13 2 6 7 21 12 32 4 11 8 27 8 35 1 8 9 16 19 18 16 3 17 -2 13 -47 -5 -53 9
-79 20 -36 9 34 28 26 32 -13 3 -31 24 -43 37 -22 10 16 45 12 45 -5 0 -9 7
-18 15 -21 8 -4 15 -15 15 -25 0 -26 51 -26 67 0 15 23 8 31 -28 31 -21 0 -27
4 -23 15 4 8 1 15 -5 15 -6 0 -11 5 -11 12 0 9 3 9 12 0 16 -16 43 -15 58 3
11 13 16 13 48 0 19 -9 36 -14 37 -13 1 2 5 21 7 44 5 39 5 41 -19 36 -38 -7
-25 19 20 40 29 14 37 25 43 55 3 21 13 43 21 50 12 10 -28 12 -191 13 l-206
0 0 -31 c0 -34 -17 -72 -29 -65 -4 3 -6 25 -4 50 l4 46 -49 0 c-26 0 -53 -5
-60 -12z m168 -58 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m160 -14 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z
m55 -94 c-4 -4 -10 -1 -12 6 -3 6 -11 12 -19 12 -15 0 -19 12 -7 23 3 4 15 -2
26 -14 10 -11 16 -23 12 -27z m-55 12 c0 -12 -55 -54 -70 -54 -23 0 -43 17
-36 29 4 6 22 11 41 11 19 0 37 5 40 10 7 11 25 14 25 4z"/>
<path d="M1821 10216 c-14 -17 -2 -26 30 -25 13 1 32 -2 42 -6 10 -4 17 -3 17
4 0 6 -16 18 -36 26 -44 18 -39 18 -53 1z"/>
<path d="M1920 10200 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2525 10200 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"/>
<path d="M2401 10151 c-52 -9 -49 -28 5 -36 50 -7 68 2 58 28 -6 18 -8 18 -63
8z"/>
<path d="M8240 10140 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8303 10130 c-26 -10 -31 -30 -8 -30 8 0 19 9 25 20 6 11 9 20 8 19
-2 0 -13 -4 -25 -9z"/>
<path d="M8465 10121 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M2395 10090 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M1271 10064 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8401 10064 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8945 10038 c-3 -7 -1 -26 4 -43 5 -16 10 -22 10 -12 1 9 8 17 16 17
21 0 19 12 -5 33 -15 13 -22 14 -25 5z"/>
<path d="M1671 10014 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8340 10010 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8835 9989 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z"/>
<path d="M2370 9970 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1712 9958 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
<path d="M1826 9925 c-6 -17 12 -31 24 -19 5 5 2 14 -5 21 -10 10 -14 9 -19
-2z"/>
<path d="M8595 9930 c-3 -5 -16 -10 -28 -11 -14 -1 -10 -4 13 -9 35 -9 65 3
54 21 -7 12 -31 11 -39 -1z"/>
<path d="M8935 9929 c-4 -6 -13 -8 -21 -5 -7 3 -16 1 -19 -4 -4 -6 6 -10 22
-11 15 0 39 -2 52 -5 18 -3 22 -1 17 12 -3 8 -6 18 -6 20 0 9 -39 3 -45 -7z"/>
<path d="M1612 9909 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M1783 9905 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z"/>
<path d="M2060 9911 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M8800 9880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1361 9844 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1320 9821 c0 -11 5 -23 10 -26 6 -4 10 5 10 19 0 14 -4 26 -10 26
-5 0 -10 -9 -10 -19z"/>
<path d="M100 8674 c0 -14 3 -14 15 -4 8 7 15 14 15 16 0 2 -7 4 -15 4 -8 0
-15 -7 -15 -16z"/>
<path d="M100 8590 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M270 8590 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M306 8591 c-3 -5 1 -11 9 -15 8 -3 15 -1 15 4 0 13 -18 22 -24 11z"/>
<path d="M153 8545 c-10 -10 -9 -16 1 -24 10 -9 15 -8 19 2 8 22 -6 37 -20 22z"/>
<path d="M322 8535 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M370 8506 c0 -8 -3 -22 -7 -32 -8 -23 13 -10 27 16 7 13 6 19 -5 24
-9 3 -15 0 -15 -8z"/>
<path d="M121 8490 c-19 -5 -40 -6 -46 -4 -17 7 -48 -43 -33 -52 6 -4 18 1 27
12 19 22 91 32 91 12 0 -9 3 -9 12 0 18 18 36 14 43 -9 7 -20 26 -22 32 -3 2
7 -51 38 -87 50 -3 1 -20 -2 -39 -6z"/>
<path d="M257 8493 c-11 -10 13 -33 34 -33 24 0 24 9 2 26 -21 15 -27 17 -36
7z"/>
<path d="M205 8400 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M340 8390 c0 -5 12 -10 26 -10 14 0 23 4 19 10 -3 6 -15 10 -26 10
-10 0 -19 -4 -19 -10z"/>
<path d="M0 8338 l1 -43 13 30 c13 31 11 55 -5 55 -5 0 -9 -19 -9 -42z"/>
<path d="M50 8360 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M106 8343 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
<path d="M380 8341 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M205 8330 c-17 -7 -15 -9 13 -9 17 -1 32 4 32 9 0 12 -17 12 -45 0z"/>
<path d="M340 8224 c0 -12 4 -14 13 -7 6 6 19 13 27 16 9 3 3 6 -12 6 -20 1
-28 -4 -28 -15z"/>
<path d="M91 7914 c13 -16 29 -19 29 -5 0 5 -9 11 -21 14 -16 4 -18 3 -8 -9z"/>
<path d="M151 7854 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M92 7844 c-7 -8 -8 -14 -3 -14 10 0 25 19 20 25 -2 1 -10 -3 -17 -11z"/>
<path d="M161 7794 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M180 7260 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M235 7216 c-18 -13 -18 -14 6 -21 32 -11 89 -12 89 -2 0 4 -10 7 -22
7 -12 0 -29 7 -38 15 -14 13 -19 13 -35 1z"/>
<path d="M145 7190 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"/>
<path d="M1 7098 c1 -50 3 -57 9 -33 11 45 11 95 0 95 -6 0 -9 -27 -9 -62z"/>
<path d="M220 7000 c-11 -7 -12 -13 -3 -32 6 -13 14 -30 16 -38 3 -8 6 6 6 33
1 26 0 47 -1 47 -2 0 -10 -4 -18 -10z"/>
<path d="M2 6960 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2 6580 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M0 6415 c0 -22 4 -34 10 -30 13 8 13 52 0 60 -6 4 -10 -8 -10 -30z"/>
<path d="M65 6400 c-19 -8 -19 -9 3 -9 12 -1 22 4 22 9 0 6 -1 10 -2 9 -2 0
-12 -4 -23 -9z"/>
<path d="M0 6290 c0 -69 1 -73 17 -58 13 14 14 20 4 31 -6 8 -11 31 -9 51 1
19 -1 38 -5 42 -4 4 -7 -26 -7 -66z"/>
<path d="M50 6214 c0 -31 23 -28 28 4 2 15 -2 22 -12 22 -11 0 -16 -9 -16 -26z"/>
<path d="M0 6153 c0 -27 4 -42 10 -38 5 3 10 15 10 26 0 10 7 19 15 19 23 0
18 14 -10 27 -25 11 -25 10 -25 -34z"/>
<path d="M80 6170 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M150 6121 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M140 6072 c0 -19 28 -33 55 -26 14 4 23 10 18 14 -13 13 -73 22 -73
12z"/>
<path d="M110 6010 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M198 6003 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M277 5851 c-3 -12 -1 -21 3 -21 15 0 23 21 12 32 -6 6 -11 2 -15 -11z"/>
<path d="M316 5781 c-7 -10 3 -41 13 -41 3 0 13 5 21 10 12 8 10 10 -10 11
-14 0 -19 3 -12 6 14 6 16 23 3 23 -6 0 -12 -4 -15 -9z"/>
<path d="M385 5690 c-3 -5 -2 -10 4 -10 5 0 12 -8 14 -17 3 -11 5 -7 6 10 1
27 -12 36 -24 17z"/>
<path d="M225 5651 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M135 5196 c-18 -13 -18 -14 3 -25 20 -11 22 -9 22 14 0 29 0 29 -25
11z"/>
<path d="M80 5169 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M175 4810 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M225 4650 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
